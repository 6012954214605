import "./CopyToLocationModal.scss";
import {Button, Modal, Select} from "antd";
import React, { useState } from "react";
import { LocationDto } from "../../../gen/client";

export interface CopyToLocationModalProps {
  visible: boolean;
  currentLocation: LocationDto;
  locations: LocationDto[];
  loading: boolean;
  newLocation: () => void;
  onCancel: () => void;
  onCopy: (newLocationId: string) => void;
}

export default function CopyToLocationModal({visible, currentLocation, locations, loading, newLocation, onCancel, onCopy}: CopyToLocationModalProps) {
  const [selectedLocation, setSelectedLocation] = useState(null);

  function onSelectLocation(val: string) {
    if (val === 'new-location') {
      setSelectedLocation(null);
      newLocation();
    } else {
      setSelectedLocation(val);
    }
  }

  return (
    <Modal className={"copy-to-location-modal"} visible={visible} onCancel={onCancel}>
      <h3>Copy menu to new location</h3>

      <Select value={selectedLocation} onChange={(val) => onSelectLocation(val)} placeholder={'Select Location'} getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}>
        <Select.Option value={'new-location'} key={'new-location'}>
          + Create New Location
        </Select.Option>
        {locations
          .filter(loc => loc.id !== currentLocation.id)
          .map(loc =>
          <Select.Option value={loc.internalId.toString()} key={loc.id}>
            <b>{loc.name}</b><br/>
            {loc.address}{loc.address && (loc.state || loc.zip) ? ', ' : ''}{loc.state} {loc.zip}
          </Select.Option>
        )}
      </Select>

      <div className="btn-container">
        <Button type="primary" size="large" loading={loading} onClick={() => onCopy(selectedLocation)}>Copy</Button>
      </div>
    </Modal>
  );
}
