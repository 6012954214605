import * as React from 'react';
import './PhoneInput.scss';
import './Loader.scss';
import NumberFormat from "react-number-format";
import {useEffect, useState} from "react";
import {UnitedStates} from "../../util/countries";
import {Input} from "antd";
import {PLACEHOLDER_PHONE} from "../../util/constants";
import { PhoneOutlined } from '@ant-design/icons';

export interface PhoneInputProps {
  value: string;
  onChange: (phone: string) => void;
  tabIndex?: number;
  country?: string;
  showAddon?: boolean;
}

export default function PhoneInput({value, tabIndex, country, onChange, showAddon}: PhoneInputProps) {
  const [phone, setPhone] = useState(value);

  useEffect(() => {
    setPhone(value);
  }, [value]);

  function handleRef(ev: HTMLInputElement) {
    if (ev) {
      ev.onblur = () => {
        onChange(ev.value);
      }
    }
  }

  function onChangeInput(inputValue: string) {
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(Number(inputValue)) && reg.test(inputValue)) || inputValue === '' || inputValue === '-') {
      setPhone(inputValue);
    }
  };

  const addon = <PhoneOutlined/>
  return <>
    {country === UnitedStates && <div className={'phone-input-container'}>
      {showAddon && <Input addonBefore={addon} size="large" placeholder={PLACEHOLDER_PHONE} value={phone}
                           onChange={(e) => onChangeInput(e.target.value)} onBlur={(ev) => onChange(ev.target.value)}/>}

        <NumberFormat tabIndex={!!tabIndex ? tabIndex : null}
                      className={`phone-input ant-input ant-input-lg ${showAddon ? 'input-with-addon' : ''}`}
                      format={"(###) ###-####"} mask="" getInputRef={handleRef} value={phone}
                      placeholder={'(555) 555-5555'} onValueChange={(values) => {
          const {value} = values;
          setPhone(value);
        }}/>

    </div>}

    {country !== UnitedStates &&
    <Input tabIndex={!!tabIndex ? tabIndex : null} addonBefore={showAddon ? addon : null} size="large"
           placeholder={'(555) 555-5555'} value={phone}
           onChange={(e) => onChangeInput(e.target.value)} onBlur={(ev) => onChange(ev.target.value)}/>}
  </>;
};

