import * as React from "react";
import "./DashboardSettings.scss";
import {Form, Tabs} from "antd";
import {BusinessCfg, BusinessDto, MenuDto} from "../../../gen/client";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import BusinessHours from "./BusinessHours";
import {getBusinessPageUrl, getTimezoneOffset} from "../../../util/Utils";
import {BusinessHoursDto} from "../../../domain/BusinessHours";
import {
  ShoppingOutlined,
  ShopOutlined,
  CalendarOutlined,
  WalletOutlined,
  NotificationOutlined,
  SettingOutlined,
} from "@ant-design/icons/lib";
import Ordering from "./Ordering";
import Payments from "./Payments";
import Notifications from "./Notifications";
import {FormItemData} from "../../../util/types";
import MobilePreviewWrapper from "../MobilePreviewWrapper";
import SettingsSite from "./SettingsSite";
import PublishButton from "../../PublishButton";
import SettingsGeneral from "./SettingsGeneral";
import {HistoryOutlined, SearchOutlined} from "@ant-design/icons";
import SettingsSeo from "./SettingsSeo";
import Revisions from "../Revisions";

enum Tab {
  Location = "1",
  Hours = "2",
  Ordering = "3",
  Payments = "4",
  Notifications = "5",
  SiteSettings = "6",
  Announcements = "7",
  SEO = "8",
  SavedVersions = "9"
}

interface DashboardSettingsProps {
  business: BusinessDto;
  menus: MenuDto[];
  saving: boolean;
  isPublished: boolean;
  publishing: boolean;
  memberId: number;
  memberStatus: number;
  onBusinessUpdate: (business: BusinessDto) => void;
  onPublish: (published: boolean) => void;
  checkPublishingPossibility: () => void;
}

const {TabPane} = Tabs;
const Keys = ['name', 'phone', 'address', 'address2', 'city', 'state', 'zip', 'country', 'website', 'email', 'instagram', 'facebook', 'twitter'];

export default function DashboardSettings({business, menus, memberId, saving, isPublished, publishing, memberStatus, onBusinessUpdate, onPublish, checkPublishingPossibility}: DashboardSettingsProps) {
  const {tab} = useParams<{tab: string}>();

  const [form] = Form.useForm();
  const [fields, setFields] = useState<FormItemData[]>([]);
  const [hours, setHours] = useState({} as BusinessHoursDto);
  const [activeTab, setActiveTab] = useState(Tab.Location.toString());
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    setActiveTab(getActiveTab(tab));
    setTimeout(() => {
      form.validateFields();
    }, 1000)
  }, [form, tab]);

  useEffect(() => {
    form.setFieldsValue(business);
    setFields(Keys.map(it => ({
      name: [it],
      value: business.config?.[it as keyof BusinessCfg]
    })));

    setCurrency(business && business.config ? business.config.currency : "$");
    setHours(JSON.parse(business?.config?.hours || "{}"));

  }, [form, business]);

  // function onUrlChangeCancel() {
  //   setFields(f => [...f, {name: 'state', value: business.config.state}]);
  //   form.setFieldsValue({
  //     name: 'state',
  //     value: business.config.state
  //   });
  // }

  function getActiveTab(tab: string) {
    if (tab === "location") return Tab.Location;
    if (tab === "hours") return Tab.Hours;
    if (tab === "payments") return Tab.Payments;
    if (tab === "ordering") return Tab.Ordering;
    if (tab === "notifications") return Tab.Notifications;
    if (tab === "status") return Tab.SiteSettings;
    if (tab === "announcements") return Tab.Announcements;
    if (tab === "seo") return Tab.SEO;
    if (tab === "saved-versions") return Tab.SavedVersions;
    return Tab.Location;
  }

  function onConfigChange(values: Partial<BusinessCfg>) {
    let updated: BusinessDto = {
      ...business,
      config: {
        ...business.config,
        timeZoneOffset: getTimezoneOffset(),
        ...values
      }
    };

    onBusinessUpdate(updated);
  }

  function onBusinessChange(values: Partial<BusinessDto>) {
    let updated: BusinessDto = {
      ...business,
      ...values
    };

    onBusinessUpdate(updated);
  }

  return <div>
    {isPublished && <div className={'site-link'}>
      <h6>Site link: <a href={getBusinessPageUrl(business)} target="_blank" rel="noreferrer">{getBusinessPageUrl(business)}</a></h6>
      <PublishButton business={business} saving={saving} publishing={publishing} isPublished={isPublished} memberStatus={memberStatus} checkPublishingPossibility={checkPublishingPossibility} />
    </div>}

    <div className={"edit-restaurant"}>
      <Form fields={fields} form={form} className={"step stacked"}>
        <div className={"main"}>
          <Tabs className={'enhanced fixed'} defaultActiveKey={Tab.Location} activeKey={activeTab} tabPosition="left" onChange={setActiveTab} >
            <TabPane tab={<> <ShopOutlined/> <span className={'settings-about-tab'}>Location</span> </>} key={Tab.Location}>
              <SettingsGeneral business={business} onConfigChange={onConfigChange}/>
            </TabPane> 

            <TabPane tab={<> <CalendarOutlined/> <span>Business Hours</span> </>} key={Tab.Hours}>
              <BusinessHours business={business} hours={hours} onConfigChange={onConfigChange}/>
            </TabPane>

            {business.orderingEnabled && <TabPane tab={<> <ShoppingOutlined/> <span>{"Ordering"}</span> </>} key={Tab.Ordering}>
              <Ordering currency={currency} business={business} onConfigChange={onConfigChange}/>
            </TabPane>}

            {business.orderingEnabled && <TabPane tab={<> <WalletOutlined/> <span>{"Payments"}</span> </>} key={Tab.Payments}>
              <Payments business={business} onConfigChange={onConfigChange}/>
            </TabPane>}

            {business.orderingEnabled && <TabPane tab={<> <NotificationOutlined/> <span>{"Notifications"}</span> </>} key={Tab.Notifications}>
              <Notifications/>
            </TabPane>}

            <TabPane tab={<> <SettingOutlined /> <span>Status</span> </>} key={Tab.SiteSettings}>
              <SettingsSite business={business} isPublished={isPublished} publishing={publishing} onPublish={onPublish} checkPublishingPossibility={checkPublishingPossibility}/>
            </TabPane>

            <TabPane tab={<> <SearchOutlined /> <span>SEO</span> </>} key={`${Tab.SEO}`}>
              <SettingsSeo business={business} onConfigChange={onBusinessChange}/>
            </TabPane>
            <TabPane tab={<> <HistoryOutlined /> <span>Saved Versions</span> </>} key={`${Tab.SavedVersions}`}>
              <Revisions visible={activeTab === Tab.SavedVersions}/>
            </TabPane>
          </Tabs>
        </div>

        <MobilePreviewWrapper menus={menus} business={business} isPublished={isPublished} />
      </Form>
    </div>
  </div>
}
