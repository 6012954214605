export const loadJS = (href: string, callback: () => void) => {
  let doc = document, lnk = doc.createElement('script'), ref;
  const refs = (doc.body || doc.getElementsByTagName("head")[0]).childNodes;
  ref = refs[refs.length - 1];
  lnk.type = 'text/javascript';
  lnk.src = href;

  lnk.addEventListener("load", function () {
    if (callback !== undefined && typeof callback === 'function') callback();
  });

  ref?.parentNode?.insertBefore(lnk, ref.nextSibling);
  return lnk;
};

export const getJSPath = () => {
  const host = window.location.host;
  let path = 'https://www.musthavemenus.com/home/mhm.umd.cjs';
  if (host === 'test.mhmfun.com') {
    path = 'https://test.mhmfun.com/home-test/mhm.umd.cjs';
  } else if (host === 'staging.mhmfun.com' || window.location.href.includes('/xstage')) {
    path = 'https://staging.mhmfun.com/home-stage/mhm.umd.cjs';
  } else if (host === 'www.musthavemenus.com') {
    path = 'https://www.musthavemenus.com/home/mhm.umd.cjs';
  } else if (host === 'localhost:3000') {
    path = 'http://localhost:3000/mhm.umd.cjs';
  }
  return `${path}?cb=${new Date().getTime()}`;
}

declare global {
  interface SetupProps {
    memberId: string;
    onSuccess?: () => void;
    onDismiss?: () => void;
    closable?: boolean;
    flow?: string;
    onBack?: () => void;
  }

  export interface ComponentProps extends SetupProps {
    container: HTMLElement;
  }

  interface Window {
    MHM: {
      ProModal: {
        upgrade: (props: SetupProps) => void;
        upgradeWithLocations: (props: SetupProps) => void;
        upgradeWithUsers: (props: SetupProps) => void;
        cancel: (props: SetupProps) => void;
        reactivate: (props: SetupProps) => void;
        reactivateFromCancelled: (props: SetupProps) => void;
        reactivateFromDeclined: (props: SetupProps) => void;
        payPastDue: (props: SetupProps) => void;
        reactivateFromPaused: (props: SetupProps) => void;
        pause: (props: SetupProps) => void;
        resume: (props: SetupProps) => void;
        addLocation: (props: SetupProps) => void;
        addUser: (props: SetupProps) => void;
        destroy: () => void;
        changeBilling: (props: SetupProps) => Promise<void>;
        reactivateDSALicense: (props: SetupProps) => Promise<void>;
        addDSA: (props: SetupProps) => void;
      },
      Components: {
        trialForm: (props: ComponentProps) => void;
      }
    },
    rewardful: (action: string, data: any) => void;
    Rewardful: {
      referral: string;
    }
    dataLayer: any[];
    $zoho: any;
    pagesense: any;
  }
}
