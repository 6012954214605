import {AxiosPromise} from "axios";
import {
  DashboardDataDto, Image, ImageDto, ImageTypeEnum, LandingPageDto, LightDashboardDataDto, LocationDto, MemberColorDto,
  MemberDto, MemberIconDto, MemberImageDto,
  MemberResourceApi, MemberStatusDto, MenuDto, NotificationCountDto, NotificationDtoScopeEnum
} from "../gen/client";
import {apiConfig, ops} from "./ApiConfig";
import {getDefaultBusinessId} from "../services/BusinessService";

const api = new MemberResourceApi(apiConfig);

const MemberApi = {
  getStatus(loginToken: string = null, businessId: number): AxiosPromise<MemberStatusDto> {
    return api.getStatus(businessId, ops(loginToken));
  },

  getMember(memberId: number, type: string = 'base'): AxiosPromise<MemberDto> {
    return api.getOne(memberId, type, ops());
  },

  getDashboardData(memberId: number, businessId: string = getDefaultBusinessId(), tkn: string = null): AxiosPromise<DashboardDataDto> {
    return api.getMemberDashboard(memberId, businessId, ops(tkn));
  },

  getLightDashboardData(memberId: number, businessId: string = getDefaultBusinessId(), tkn: string = null): AxiosPromise<LightDashboardDataDto> {
    return api.getLightMemberDashboard(memberId, businessId, ops(tkn));
  },

  clearNotifications(memberId: number, scope: NotificationDtoScopeEnum = null): AxiosPromise<Boolean> {
    return api.clearNotifications(memberId, scope, ops());
  },

  countNewNotifications(memberId: number): AxiosPromise<NotificationCountDto> {
    return api.getNotificationCount(memberId, ops());
  },

  markNotificationAsOld(memberId: string, notificationId: string): AxiosPromise<boolean> {
    return api.markNotificationAsOld(memberId, notificationId, ops());
  },

  uploadImage(memberId: number, locationId: number, type: ImageTypeEnum, image: any): AxiosPromise<Image> {
    return api.uploadMemberImage(memberId, type, locationId, image, ops());
  },

  getMemberImages(memberId: number, locationId: number): AxiosPromise<MemberImageDto[]> {
    return api.getMemberImages(memberId, locationId, ops());
  },

  getMemberIcons(memberId: number, locationId: number): AxiosPromise<MemberIconDto[]> {
    return api.getMemberIcons(memberId, locationId, ops());
  },

  uploadIcon(memberId: number, locationId: number, type: ImageTypeEnum, image: any): AxiosPromise<Image> {
    return api.uploadMemberIcon(memberId, type, locationId, image, ops());
  },

  deleteIcon(iconId: string): AxiosPromise<Boolean> {
    return api.deleteMemberIcon(iconId, ops());
  },

  getUploads(memberId: number, type: ImageTypeEnum): AxiosPromise<ImageDto[]> {
    return api.getMemberUploads(memberId, type, ops());
  },

  getColors(memberId: number): AxiosPromise<MemberColorDto[]> {
    return api.getMemberColors(memberId, ops());
  },

  getLandingPages(memberId: number): AxiosPromise<LandingPageDto[]> {
    return api.getMemberLandingPages(memberId, ops());
  },

  getLocations(memberId: number): AxiosPromise<LocationDto[]> {
    return api.getMemberLocations(memberId, ops());
  },

  copyMenusToLocation(memberId: number, oldLocationId: number, newLocationId: number): AxiosPromise<MenuDto[]> {
    return api.copyMenusToLocation(memberId, oldLocationId, newLocationId, ops());
  }

  // getQRCodes(memberId: number): AxiosPromise<QRCodeDto[]> {
  //   return api.getQRCodes(memberId, ops());
  // }
};

export default MemberApi;
