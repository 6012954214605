import * as React from 'react';
import './LightBusinessSocial.scss';
import {BusinessDto, LocationDto} from "../../gen/client";
import FacebookIcon from "../misc/icons/FacebookIcon";
import TwitterIcon from "../misc/icons/TwitterIcon";
import InstagramIcon from "../misc/icons/InstagramIcon";
import WebsiteIcon from "../misc/icons/WebsiteIcon";
import EmailIcon from "../misc/icons/EmailIcon";

interface LightBusinessSocialProps {
  business: BusinessDto;
  location: LocationDto;
  isPreview: boolean;
}

export default function LightBusinessSocial({business, location, isPreview}: LightBusinessSocialProps) {
  return <ul className={'biz-social'}>
    {location.website && !isPreview && business.config.showWebsite && <li><a href={`//${location.website}`} target={'_blank'} rel={'noopener noreferrer'}><WebsiteIcon/></a></li>}
    {location.email && !isPreview && business.config.showEmail && <li><a href={`mailto:${location.email}`}><EmailIcon/></a></li>}
    {location.facebook && !isPreview && business.config.showFacebook && <li><a href={`//${location.facebook}`} target={'_blank'} rel={'noopener noreferrer'}><FacebookIcon/></a></li>}
    {location.twitter && !isPreview && business.config.showTwitter && <li><a href={`https://twitter.com/${location.twitter?.replace('@', '')}`} target={'_blank'} rel={'noopener noreferrer'}><img src="https://timber.mhmcdn.com/site/marketing/icons/X.svg"/></a></li>}
    {location.instagram && !isPreview && business.config.showInstagram && <li><a href={`https://instagram.com/${location.instagram?.replace('@', '')}`} target={'_blank'} rel={'noopener noreferrer'}><InstagramIcon/></a></li>}

    {location.website && isPreview && business.config.showWebsite && <li><span className={'lk'}><WebsiteIcon/></span></li>}
    {location.email && isPreview && business.config.showEmail && <li><span className={'lk'}><EmailIcon/></span></li>}
    {location.facebook && isPreview && business.config.showFacebook && <li><span className={'lk'}><FacebookIcon/></span></li>}
    {location.twitter && isPreview && business.config.showTwitter && <li><span className={'lk'}><img src="https://timber.mhmcdn.com/site/marketing/icons/X.svg"/></span></li>}
    {location.instagram && isPreview && business.config.showInstagram && <li><span className={'lk'}><InstagramIcon/></span></li>}
  </ul>
}
