import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";

export interface CopyMenuImportHelpModalProps {
  visible: boolean;
  onClose: () => void;
}

export default function CopyMenuImportHelpModal({ visible, onClose }: CopyMenuImportHelpModalProps) {
  const [isNavBySection, ] = useState(true);

  return <Modal
    className={"one modal import-item-modal copy-menu-help-modal"}
    onCancel={() => onClose()}
    width={800}
    visible={visible}
    zIndex={1006}
  >
    <header>
      <div className="close-btn" onClick={onClose}><CloseOutlined /></div>
    </header>

    {isNavBySection && <> 
      <div className="video-container">
        <video autoPlay loop muted playsInline src="https://timber.mhmcdn.com/site/om/RegularNavigation.mp4"></video>
      </div>
      <h3>Navigate by Section</h3>
      <p>
        By default, customers will see your menu names as navigation, and there can be many menus.<br/><br/>
        With “Navigate by Section,” customers will see your menu groups as navigation.
      </p>
    </>}

    {/*!isNavBySection && <>
      <div className="video-container">
        <video autoPlay loop muted playsInline src="https://timber.mhmcdn.com/site/om/MultimenuNavigation.mp4"></video>
      </div>
      <h3>Multi-Menu Navigation</h3>
      <p>Customers will see your menu name as navigation, and there can be many menus.</p>
    </>*/}

    {/*<div className="slide-nav">
      <LeftOutlined onClick={() => setMode('regular')} style={{ visibility: mode === 'regular' ? 'hidden' : 'visible' }}/>
      {mode === 'regular' && <span>1 / 2</span>}
      {mode === 'multi' && <span>2 / 2</span>}
      <RightOutlined onClick={() => setMode('multi')} style={{ visibility: mode === 'multi' ? 'hidden' : 'visible' }}/>
    </div>*/}
  </Modal>
}
