import * as React from 'react';
import {Button, Input, Switch, Tooltip} from "antd";
import {useEffect, useState} from "react";
import {MenuItemIcon, MenuItemModifier, MenuNode} from "../../../domain/Menu";
import {BusinessDto, MemberIconDto, MemberImageDto} from "../../../gen/client";
import {DeleteOutlined} from "@ant-design/icons/lib";
import styles from './MenuSection.module.scss';
import {DndContext, DragEndEvent, DragOverlay, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext} from '@dnd-kit/sortable';
import {MenuItemSortable} from './MenuItemSortable';
import {DND_COLLISION_DETECTION, DND_MODIFIERS, DND_SORTABLE_STRATEGY} from '../../../util/constants';
import {MenuDataType, MenuItemModifierDetail} from '../../../util/types';
import {createPortal} from 'react-dom';
import DeleteMenuDataModal from "./DeleteMenuDataModal";

interface MenuSectionProps {
  section: MenuNode;
  business: BusinessDto;
  currency: string;
  modifiers: MenuItemModifierDetail[];
  reference: any;
  scrollToBottom: boolean;
  onSectionUpdate: (section: Partial<MenuNode>) => void;
  onModifierRemovedGlobal: (m: MenuItemModifier) => void;
  onItemUpdate: (sectionId: string, itemId: string, patch: Partial<MenuNode>) => void;
  onItemRemove: (sectionId: string, itemId: string) => void;
  onItemAdd: (sectionId: string) => void;
  onItemSort: (sectionId: string, nodes: MenuNode[]) => void;
  onSectionRemove: (id: string) => void;
  onCurrencyChange: (currency: string) => void;
  onItemImport: (section: MenuNode) => void;
  onItemClick: (itemId: string) => void;
  editItemIds?: string[];
  onCancel: () => void;
  memberImages: MemberImageDto[];
  setMemberImages: (images: MemberImageDto[]) => void;
  memberIcons: MemberIconDto[];
  setMemberIcons: (icons: MemberIconDto[]) => void;
  activeMenuIcons: MenuItemIcon[];
  updateMenuActiveIcons: () => void;
  setSelectedMemberImage: (image: MemberImageDto | MemberIconDto) => void;
  setAltTextModal: (visible: boolean) => void;
}

export default function MenuSection({
                                      section, business, modifiers, currency, reference, scrollToBottom, onSectionUpdate, onItemRemove, onItemAdd, onItemUpdate, onSectionRemove,
                                      onItemSort, onCurrencyChange, onModifierRemovedGlobal, onItemImport, onItemClick, editItemIds, onCancel, 
                                      memberImages, setMemberImages, memberIcons, setMemberIcons, activeMenuIcons, updateMenuActiveIcons, setSelectedMemberImage, setAltTextModal
                                    }: MenuSectionProps) {
  const [isRemoveModal, setRemoveModal] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [sortable, setSortable] = useState(false);
  const [sectionName, setSectionName] = useState('');
  const DND_SENSORS = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    setSectionName(section.name);
  }, [section, section.name])

  useEffect(() => {
    if (!section || !section.nodes || !editItemIds) return;

    setSortable(true);
    /*section.nodes.map(n => n.id).forEach(id => {
      if (!!editItemIds?.find(it => it === id)) {
        setSortable(false);
      }
    });*/
  }, [editItemIds, section]);

  function onRemove(sectionId: string, itemId: string) {
    onItemRemove(sectionId, itemId);
  }

  function onDragEnd(event: DragEndEvent) {
    const {active, over} = event;

    if (active.id !== over.id) {
      const oldIndex = section.nodes.findIndex(it => it.id === active.id);
      const newIndex = section.nodes.findIndex(it => it.id === over.id);

      onItemSort(section.id, arrayMove(section.nodes, oldIndex, newIndex));
    }
    setDragging(false);
  }

  function onDragStart() {
    setDragging(true);
  }

  function onSectionNameBlur(value: string) {
    onSectionUpdate({name: value || 'Unnamed Group'});
  }

  return (
    <div className={`${styles.section} ${!section.available ? ' unavailable' : ''}`} ref={reference}>
      <header>
        <Input 
          className={'nm'} 
          size="large" 
          value={sectionName}
          onChange={(ev) => setSectionName(ev.target.value)} 
          autoFocus={!section.name && scrollToBottom} 
          placeholder={'Group Name'} 
          onBlur={(ev) => onSectionNameBlur(ev.target.value)}
        />
        <section className={styles.publishSection}>
          <Tooltip title={section.available ? 'Hide' : 'Show'} placement="bottom">
            <Switch className={'scc'} checked={section.available} onChange={() => onSectionUpdate({available: !section.available})} size={"small"}/>
          </Tooltip>
        </section>
        <Tooltip title="Delete" placement="bottom">
          <Button type="link" className={styles.lk} onClick={() => setRemoveModal(true)}><DeleteOutlined/></Button>
        </Tooltip>
      </header>
      <section>
        <Input.TextArea defaultValue={section.description} placeholder={'Group Note'} onBlur={ev => onSectionUpdate({description: ev.target.value})}/>
        <p/>
      </section>

      <div className={'items'}>
        <DndContext modifiers={DND_MODIFIERS} sensors={DND_SENSORS} collisionDetection={DND_COLLISION_DETECTION} onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {createPortal(<DragOverlay> </DragOverlay>, document.body)}

          <SortableContext strategy={DND_SORTABLE_STRATEGY} items={section.nodes.map(it => it.id)}>
            {section.nodes.map((node, idx) => <MenuItemSortable
              key={node.id}
              showDragHandle={section.nodes.length > 1}
              sortable={sortable}
              business={business}
              item={node}
              modifiers={modifiers}
              onItemUpdate={onItemUpdate}
              currency={currency}
              sectionId={section.id}
              dragging={dragging}
              scrollToBottom={scrollToBottom && idx === section.nodes.length - 1}
              onItemRemove={onRemove}
              onCurrencyChange={onCurrencyChange}
              onModifierRemovedGlobal={onModifierRemovedGlobal}
              editMode={!!editItemIds?.find(it => it === node.id)}
              onCancel={onCancel}
              onClick={() => onItemClick(node.id)}
              memberImages={memberImages}
              setMemberImages={setMemberImages}
              memberIcons={memberIcons}
              setMemberIcons={setMemberIcons}
              activeMenuIcons={activeMenuIcons}
              updateMenuActiveIcons={updateMenuActiveIcons}
              setSelectedMemberImage={setSelectedMemberImage} 
              setAltTextModal={setAltTextModal}
              />)}
          </SortableContext>
        </DndContext>
      </div>

      <div className={styles.add}>
        <Button type={'default'} className={styles.defaultButton + ' btn-med btn-sec-1'} onClick={() => onItemAdd(section.id)}>+ New Item</Button>
        <Button type={'default'} className={styles.defaultButton + ' btn-med btn-sec-2' } onClick={() => onItemImport(section)}>+ Saved Items</Button>
      </div>

      <DeleteMenuDataModal visible={isRemoveModal} onConfirm={() => onSectionRemove(section.id)} onCancel={() => setRemoveModal(false)} type={MenuDataType.SECTION} />
    </div>
  );
};
