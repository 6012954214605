import * as React from 'react';
import { useState } from 'react';
import ShareDropdown from "./ShareDropdown";
import {Dropdown, Button} from "antd";
import { BusinessDto } from '../gen/client';
import { ShareAltOutlined } from '@ant-design/icons';

export interface PublishButtonProps {
    business: BusinessDto;
    saving: boolean;
    publishing: boolean;
    isPublished: boolean;
    memberStatus: number;
    checkPublishingPossibility: () => void;
}

export default function PublishButton({business, saving, publishing, isPublished, memberStatus, checkPublishingPossibility} : PublishButtonProps) {
    const [shareVisible, setShareVisible] = useState(false);

    function handleShareVisibleChange(visible : boolean) {
        setShareVisible(visible);
    }

    return (
        <div className={'publish'}>
            {saving && <span className={'info'}>saving...</span>}
            {!saving && <span className={'info'}>changes saved</span>}

            {!isPublished &&
            <Button className={'publish-btn'} type={'primary'} loading={publishing} disabled={publishing} onClick={() => checkPublishingPossibility()}>
                Publish
            </Button>}

            {isPublished &&
            <>
            <Dropdown placement={'bottomLeft'} trigger={['click']} overlay={<ShareDropdown business={business} memberStatus={memberStatus} shareClose={() => { setShareVisible(false) }}/>} overlayClassName={'share-drop'} visible={shareVisible} onVisibleChange={(visible) => handleShareVisibleChange(visible)}>
                <Button className={'share-btn btn-sm btn-sec-1'}>
                    <span className={'share-icon'} style={{display: 'none'}}>
                        <ShareAltOutlined style={{fontSize: '20px', fontWeight: 'bold'}} />
                    </span>
                    <span className={'share-text'}>
                        Share
                    </span>
                </Button>
            </Dropdown>
            <Button className={'publish-changes-btn btn-sm btn-sec-2'} loading={publishing} disabled={publishing} onClick={() => checkPublishingPossibility()}>
                Publish Changes
            </Button>
            </>}
        </div>
    );
}