import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import './Dashboard.scss';
import MemberApi from "../../api/MemberApi";
import {Route, Switch, useHistory} from "react-router";
import DashboardMenus from "./menu/DashboardMenus";
import DashboardOrders from "./DashboardOrders";
import {BusinessDto, MenuDto, MenuSequenceDto} from "../../gen/client";
import {message, Modal} from "antd";
import DashboardSettings from "./settings/DashboardSettings";
import {AxiosError} from "axios";
import {handleServerError} from "../../util/ErrorHandler";
import ShareBusiness from "./ShareBusiness";
import DashboardCustomers from "./customer/DashboardCustomers";
import {getBusinessPath, getMenusPath, redirectToLogin, RoutesEnum} from '../../RoutesEnum';
import MenuApi from '../../api/MenuApi';
import {MenuNode, MenuNodeType} from '../../domain/Menu';
import {EmptyBusiness, EmptyMenu} from "../../util/constants";
import DashboardHeader from "./DashboardHeader";
import DashboardDesign from "./DashboardDesign";
import {businessSubject, menuSubject} from '../../store/Subjects';
import {BusinessApi} from '../../api/BusinessApi';
import _ from 'lodash';
import {env} from '../../env/env';
import FirstTimePublishedModal from './FirstTimePublishedModal';
import {Action, hideUndoNotification, History, showUndoNotification} from "../../services/HistoryService";
import {setPageTitle} from "../../util/seo";
import Storage from "../../util/Storage";
import useQueryString from "../../hooks/useQueryString";
import {isAuthError, isNotFoundError, isPaymentRequiredError, uuid} from "../../util/Utils";

import {hasValidMenu, timberItemToMenuItem, TimberMenuObject} from "../../services/MenuService";
import {getDefaultBusinessId, hasValidBusiness} from "../../services/BusinessService";
import {PublishFailReason} from "../../util/constants";
import PublishModal from "./PublishModal";
import CannotPublishModal from "./CannotPublishModal";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentLocation, locationIdState, memberState, savingState} from "../../store/atoms";
import EditLocationModal from "./settings/EditLocationModal";
import AutoLogout from "./AutoLogout";
import CopyMenuImportModal from './menu/CopyMenuImportModal';

function Dashboard() {
  const history = useHistory();

  const member = useRecoilValue(memberState);
  const businessId = useRecoilValue(locationIdState);

  const [memberStatus, setMemberStatus] = useState(5);
  const [menus, setMenus] = useState([]);
  const [menu, setMenu] = useState(EmptyMenu);
  const businessRef = useRef(EmptyBusiness);
  const [, setLoading] = useState(true);
  const [isShareModal, setShareModal] = useState(false);
  const [saving, setSaving] = useRecoilState(savingState);
  const [publishing, setPublishing] = useState(false);
  const [hasPublishedBefore, setPublishedBefore] = useState(false);
  const [firstTimePublishedModal, setFirstTimePublishedModal] = useState(false);

  const [isCopyMenuModal, setCopyMenuModal] = useState(false);
  const [copyDesignImportLoading, setCopyDesignImportLoading] = useState(false);

  const [publishModal, setPublishModal] = useState(false);
  const [cannotPublishReason, setCannotPublishReason] = useState(null);
  const [enterBusinessInfoModal, setEnterBusinessInfoModal] = useState(false);
  const [location, setLocation] = useRecoilState(currentLocation);

  const [tkn] = useQueryString<string>('tkn', Storage.getToken());

  useEffect(() => {
    if (!!tkn && tkn !== 'null') {
      Storage.setToken(tkn);

      if (history && businessId) {
        if (history.location.pathname.indexOf(RoutesEnum.DashboardSettingsStatus) > -1) {
          history.replace(`${RoutesEnum.DashboardSettingsStatus}?businessId=${businessId}`);
        } else {
          history.replace(`${RoutesEnum.App}?businessId=${businessId}`);
        }
      }
    }
  }, [businessId, history, tkn]);

  const saveChanges = useRef(_.debounce((updated: BusinessDto, tkn: string = Storage.getToken()) => {
    setSaving(true);
    BusinessApi.update(updated.id, updated, tkn).then((resp) => {
      if (resp.data.url !== businessRef.current.url) {
        businessRef.current = resp.data
      }
      setSaving(false);
    })
      .catch((err: AxiosError) => {
        if (!isNotFoundError(err) && !isAuthError(err)) {
          handleServerError(err);
        }
        setSaving(false);
      });
  }, env.autoSaveDebounce));

  const fetchDashboardData = useCallback((memberId, businessId) => {
    if (memberId === null) return;
    if (!tkn) return;

    MemberApi.getDashboardData(memberId, businessId || getDefaultBusinessId(), tkn).then(resp => {
      setMenus(resp.data.menus);
      setMemberStatus(resp.data.memberStatus);

      const biz = resp.data.business;
      businessSubject.next(biz);
      //setIsFirstTimeModal(!biz.initialConfigured || !biz.config?.name);
      setPublishedBefore(biz.published);
      setLoading(false);
    }).catch((err: AxiosError) => {
      if (isPaymentRequiredError(err)) {
        redirectToLogin();
      } else {
        if (!isNotFoundError(err)) {
          handleServerError(err);
        }
        setLoading(false);
      }
    });
  }, [tkn]);

  useEffect(() => {
    setPageTitle('Dashboard');
  }, []);

  useEffect(() => {
    const observable = businessSubject.subscribe((updated) => {
      businessRef.current = updated;
      if (businessRef.current !== EmptyBusiness) {
        saveChanges.current(updated, tkn);
      }
    });
    return () => {
      observable.unsubscribe();
    };
  }, [tkn]);

  useEffect(() => {
    if (!member.id) return;
    fetchDashboardData(member.id, businessId);
  }, [fetchDashboardData, member.id, businessId]);

  useEffect(() => {
    if (!businessId) return;

    BusinessApi.getLocation(parseFloat(businessId)).then(resp => {
      setLocation(resp.data);
    });
  }, [businessId, setLocation]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSaving(true);
      BusinessApi.saveRevision(businessRef.current.id).then(() => {
        setSaving(false);
      });
    }, 60000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [setSaving]);

  function onMenuSave(finished: boolean) {
    setSaving(!finished);
  }

  function getFirstValidMenu(menus: MenuDto[]) {
    if (menus.length) {
      return menus.find(it => !it.removed);
    }
  }

  function onMenuRemoved(id: string) {
    menus.find(it => it.id === id).removed = true;
    setMenus([...menus]);
    const menu = getFirstValidMenu(menus);
    history.push(!menu ? getBusinessPath(businessRef.current.id) : getMenusPath(businessRef.current.id, menu.id));
    setTimeout(() => {
      showUndoMenuRemoved(id);
    }, 100);
  }

  function showUndoMenuRemoved(id: string) {
    History.push(Action.RemoveMenu, {id});
    showUndoNotification(Action.RemoveMenu, () => {
      MenuApi.restoreMenu(id).then(() => {
        message.success('Menu restored', 3);
        menus.find(it => it.id === id).removed = false;
        setMenus([...menus]);
        hideUndoNotification();
        history.push(getMenusPath(businessRef.current.id, id));
      }).catch(handleServerError);
    });
  }

  function onMenuAdded(menu: MenuDto) {
    setMenus(menus => [...menus, menu]);
  }

  function onMenusAdded(newMenus: MenuDto[]) {
    setMenus(menus => [...menus, ...newMenus]);
  }

  function onMenuSort(menus: MenuDto[]) {
    const sequences: MenuSequenceDto[] = [];
    menus.forEach((it, idx) => {
      sequences.push({sequence: idx + 1, menuId: it.id});
      it.sequence = idx + 1;
    });
    MenuApi.updateSequences(sequences, businessId || '');

    setMenus([...menus]);
  }

  function onSectionSort(menu: MenuDto, sections: MenuNode[]) {
    const list = [...menus];
    list.forEach(it => {
      if (it.id === menu.id) {
        it.nodes = JSON.stringify(sections);

        menuSubject.next({...it, nodes: JSON.stringify(sections)});
      }
    });
    setMenus(list);
  }

  function onMenuEdited(menu: MenuDto) {
    const list = [...menus];

    if (menu && list.length) {
      const idx = menus.findIndex(it => it.id === menu.id);

      if (idx > -1) {
        list[idx] = menu;

        setMenus(list);
      }
    }
  }

  function onBusinessUpdate(updated: BusinessDto) {
    businessSubject.next({
      ...businessRef.current,
      ...updated,
      config: {
        ...businessRef.current.config,
        ...updated.config
      }
    });
  }

  function onCurrencyChange(currency: string) {
    businessSubject.next({...businessRef.current, ...{config: {currency}}});
  }

  function onPublish(published: boolean) {
    if (published) {
      publishChanges();
    } else {
      unpublishChanges();
    }
  }

  function unpublishChanges() {
    setPublishing(true);
    BusinessApi.unpublishChanges(businessRef.current.id).then(() => {
      setPublishing(false);
      message.success('Site is unpublished.', 2);
      setPublishedBefore(false);
    }).catch((err: AxiosError) => {
      setPublishing(false);
      handleServerError(err);
    });
  }

  function publishChanges() {
    setPublishing(true);

    BusinessApi.publishChanges(businessRef.current.id).then((resp) => {
      setPublishing(false);
      message.success('Changes are published.', 2);

      businessRef.current = resp.data;

      if (!hasPublishedBefore) {
        setFirstTimePublishedModal(true);
        setPublishedBefore(true);
      }
    }).catch((err: AxiosError) => {
      setPublishing(false);
      handleServerError(err);
    });
  }

  function checkPublishingPossibility() {
    const menusOK = hasValidMenu(menus);
    const businessOK = hasValidBusiness(location);
    if (menusOK && businessOK) {
      setPublishing(true);
      BusinessApi.canPublishChanges(businessRef.current.id).then(resp => {
        if (resp.data) {
          onPublish(true);
        } else {
          setPublishing(false);
          setCannotPublishReason(PublishFailReason.URL_TAKEN);
        }
      }).catch(() => {
        setPublishing(false);
        setCannotPublishReason(PublishFailReason.URL_TAKEN);
      })
    } else if (!businessOK) {
      setEnterBusinessInfoModal(true);
    } else {
      setCannotPublishReason(!menusOK ? PublishFailReason.NO_MENUS : PublishFailReason.NO_BUSINESS_INFO);
    }
  }

  function copyMenuImport(isNavBySection: boolean, groupedObjects: TimberMenuObject[]) { 
    setCopyDesignImportLoading(true);   
    if (isNavBySection) {
      let unnamedMenuIdx = 1;
      menus.filter(it => !it.removed).forEach(m => {
        if (m.name.indexOf('Menu ') > -1) {
          const menuNum = parseInt(m.name.replace('Menu ', ''));
          if (menuNum && menuNum + 1 > unnamedMenuIdx) {
            unnamedMenuIdx = menuNum + 1;
          }
        }
      })
      const toSaveMenus = groupedObjects.map((m) => {
        let mName = m.name;
        if (!m.name) {
          mName = `Menu ${unnamedMenuIdx}`;
          unnamedMenuIdx += 1;
        }
        const singleSection = {
          id: uuid(),
          available: m.visible,
          name: mName,
          description: '',
          type: MenuNodeType.SECTION,
          nodes: timberItemToMenuItem(m.items)
        } as MenuNode;
        return {
          name: mName,
          details: m.notes,
          nodes: JSON.stringify([singleSection]),
          active: m.visible,
          businessId
        } as MenuDto
      });
      MenuApi.saveMenus(toSaveMenus).then(resp => {
        onMenusAdded(resp.data);
        onMenuSave(true);
        history.push(getMenusPath(businessId, resp.data[0].id));
        setCopyDesignImportLoading(false);
        setCopyMenuModal(false);
      }).catch((err) => {
        handleServerError(err);
        setCopyDesignImportLoading(false);
      });
    } else {
      const newSections = groupedObjects.map(m => {
        return {
          id: uuid(),
          available: m.visible,
          name: m.name || 'Unnamed Group',
          description: m.notes,
          type: MenuNodeType.SECTION,
          nodes: timberItemToMenuItem(m.items)
        } as MenuNode
      })
      const allNodes = [...JSON.parse(menu.nodes), ...newSections];
      menu.nodes = JSON.stringify(allNodes);
      MenuApi.updateMenu(menu).then(() => {
        onMenuSave(true);
        onMenuEdited({...menu});
        setMenu(menu);
        history.push(getMenusPath(businessId, menu.id));
        setCopyDesignImportLoading(false);
        setCopyMenuModal(false);
      }).catch((err) => {
        handleServerError(err);
        setCopyDesignImportLoading(false);
      });
    }
  }

  return <div className={'dashboard'}>

    <AutoLogout />
    <DashboardHeader business={businessRef.current} menus={menus} memberStatus={memberStatus} onBusinessUpdate={onBusinessUpdate} saving={saving} publishing={publishing} onPublish={onPublish} isPublished={hasPublishedBefore} checkPublishingPossibility={checkPublishingPossibility} setCopyMenuModal={setCopyMenuModal} />

    <main>
      <div className={'scroller'}>
      <Switch>
          <Route path={`${RoutesEnum.DashboardOrders}/:id?`}>
            <DashboardOrders business={businessRef.current}/>
          </Route>

          <Route path={RoutesEnum.DashboardCustomers}>
            <DashboardCustomers business={businessRef.current} onBusinessUpdate={onBusinessUpdate}/>
          </Route>

          <Route path={`${RoutesEnum.DashboardSettings}/:tab?`}>
            <DashboardSettings business={businessRef.current} menus={menus} memberId={member.id} memberStatus={memberStatus} onBusinessUpdate={onBusinessUpdate} saving={saving} isPublished={hasPublishedBefore} publishing={publishing} onPublish={onPublish} checkPublishingPossibility={checkPublishingPossibility} />
          </Route>

          <Route path={`${RoutesEnum.DashboardDesign}`}>
            <DashboardDesign business={businessRef.current} menus={menus}  memberStatus={memberStatus} onBusinessUpdate={onBusinessUpdate} saving={saving} publishing={publishing} isPublished={hasPublishedBefore} checkPublishingPossibility={checkPublishingPossibility} />
          </Route>

          {/*<Route path={`${RoutesEnum.DashboardBusiness}/:id`}>
            <BusinessOverview business={businessRef.current} location={location} isLoading={loading} saving={saving} publishing={publishing} menus={menus} isPublished={hasPublishedBefore}  memberStatus={memberStatus} onBusinessUpdate={onBusinessUpdate} onPublish={onPublish} checkPublishingPossibility={checkPublishingPossibility} />
          </Route>*/}

          <Route path={[RoutesEnum.DashboardMenus, RoutesEnum.App]}>
            <DashboardMenus business={businessRef.current} menus={menus} menu={menu} setMenu={setMenu} memberStatus={memberStatus} onMenuAdded={onMenuAdded} onMenuRemoved={onMenuRemoved} onMenuEdited={onMenuEdited} saving={saving} publishing={publishing} checkPublishingPossibility={checkPublishingPossibility}
                            isPublished={hasPublishedBefore} onMenuSort={onMenuSort} onSectionSort={onSectionSort} onCurrencyChange={onCurrencyChange} showShareModal={() => setShareModal(true)} onSave={onMenuSave} setCopyMenuModal={setCopyMenuModal}/>
          </Route>
        </Switch>
      </div>

      <Modal className={'share-modal'} visible={isShareModal} onCancel={() => setShareModal(false)}>
        <ShareBusiness business={businessRef.current} showShareModal={() => setShareModal(false)}/>
      </Modal>

      {firstTimePublishedModal && <FirstTimePublishedModal business={businessRef.current} onCancel={() => setFirstTimePublishedModal(false)}/>}

      <PublishModal visible={publishModal} onConfirm={() => {onPublish(true); setPublishModal(false);}} onCancel={() => setPublishModal(false)}/>
      <CannotPublishModal visible={cannotPublishReason !== null} onOk={() => setCannotPublishReason(null)} reason={cannotPublishReason}/>

      <EditLocationModal locationId={parseFloat(businessRef.current.id)} memberId={member.id} visible={enterBusinessInfoModal} onClose={() => setEnterBusinessInfoModal(false)} onSave={() => { setEnterBusinessInfoModal(false); setPublishModal(true)} } prePublish/>

      <CopyMenuImportModal visible={isCopyMenuModal} business={businessRef.current} copyDesignImportLoading={copyDesignImportLoading} onClose={() => setCopyMenuModal(false)} onCopy={copyMenuImport} />
    </main>
  </div>;
}

export default Dashboard;
