// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActionButton
 */
export interface ActionButton {
    /**
     * 
     * @type {string}
     * @memberof ActionButton
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionButton
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionButton
     */
    payload?: string;
}
/**
 * 
 * @export
 * @interface AddOrderItemDto
 */
export interface AddOrderItemDto {
    /**
     * 
     * @type {string}
     * @memberof AddOrderItemDto
     */
    menuItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrderItemDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AddOrderItemDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof AddOrderItemDto
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrderItemDto
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrderItemDto
     */
    menuId?: string;
    /**
     * 
     * @type {Array<OrderItemModifierDto>}
     * @memberof AddOrderItemDto
     */
    modifiers?: Array<OrderItemModifierDto>;
}
/**
 * 
 * @export
 * @interface AdminDashboardDto
 */
export interface AdminDashboardDto {
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardDto
     */
    memberCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardDto
     */
    orderCount?: number;
    /**
     * 
     * @type {Array<MemberDto>}
     * @memberof AdminDashboardDto
     */
    members?: Array<MemberDto>;
    /**
     * 
     * @type {Array<OrderDto>}
     * @memberof AdminDashboardDto
     */
    orders?: Array<OrderDto>;
}
/**
 * 
 * @export
 * @interface AppConfigDto
 */
export interface AppConfigDto {
    /**
     * 
     * @type {string}
     * @memberof AppConfigDto
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof AppConfigDto
     */
    s3Path?: string;
    /**
     * 
     * @type {string}
     * @memberof AppConfigDto
     */
    paymentToken?: string;
}
/**
 * 
 * @export
 * @interface AuthResponseDto
 */
export interface AuthResponseDto {
    /**
     * 
     * @type {MemberDto}
     * @memberof AuthResponseDto
     */
    member?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof AuthResponseDto
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface BrandColorDto
 */
export interface BrandColorDto {
    /**
     * 
     * @type {string}
     * @memberof BrandColorDto
     */
    colorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandColorDto
     */
    brandId?: string;
}
/**
 * 
 * @export
 * @interface BrandLogoDto
 */
export interface BrandLogoDto {
    /**
     * 
     * @type {string}
     * @memberof BrandLogoDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandLogoDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandLogoDto
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandLogoDto
     */
    brandId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandLogoDto
     */
    removed?: boolean;
}
/**
 * 
 * @export
 * @interface Business
 */
export interface Business {
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Business
     */
    orderingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Business
     */
    _default?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Business
     */
    migrated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    viewCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    config?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    publishedConfig?: string;
    /**
     * 
     * @type {Member}
     * @memberof Business
     */
    owner?: Member;
    /**
     * 
     * @type {Array<CustomTax>}
     * @memberof Business
     */
    taxes?: Array<CustomTax>;
    /**
     * 
     * @type {Array<Menu>}
     * @memberof Business
     */
    menus?: Array<Menu>;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    updatedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    createdDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Business
     */
    removed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    publishedDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Business
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    customUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Business
     */
    imagesMigrated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    teamId?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    inUseInitials?: string;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    inUseId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Business
     */
    initialConfigured?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Business
     */
    published?: boolean;
}
/**
 * 
 * @export
 * @interface BusinessCfg
 */
export interface BusinessCfg {
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    currency?: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessCfg
     */
    minTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessCfg
     */
    pickUpTime?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessCfg
     */
    deliveryTime?: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessCfg
     */
    deliveryFee?: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    terms?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    hours?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    use24HourFormat?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BusinessCfg
     */
    timeZoneOffset?: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    facebook?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    cover?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    about?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    open?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    notificationEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    notificationSound?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    notificationSms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    ordersInAdvance?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    ordersTips?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    orderDelivery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    orderDeliveryToTable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    pickUpOrders?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    onlineCardPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    deliveryCardPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    deliveryCashPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    coupons?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    allowFeedback?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    googleAnalyticsTag?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    showBusinessName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    showAddress?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    showPhone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    showWebsite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    showEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    showTwitter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    showFacebook?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    showInstagram?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    businessNameColor?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    bannerColor?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    bannerTextColor?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    menuTextColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    orderNowButton?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    orderNowPhone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    toDoDesign?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessCfg
     */
    toDoShare?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    colorPairs?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    announcementBanner?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    customUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessCfg
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface BusinessDto
 */
export interface BusinessDto {
    /**
     * 
     * @type {string}
     * @memberof BusinessDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDto
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessDto
     */
    viewCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessDto
     */
    orderingEnabled?: boolean;
    /**
     * 
     * @type {BusinessCfg}
     * @memberof BusinessDto
     */
    config?: BusinessCfg;
    /**
     * 
     * @type {WebsiteDto}
     * @memberof BusinessDto
     */
    website?: WebsiteDto;
    /**
     * 
     * @type {Array<RatingDto>}
     * @memberof BusinessDto
     */
    ratings?: Array<RatingDto>;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessDto
     */
    published?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessDto
     */
    _default?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessDto
     */
    migrated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessDto
     */
    customUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessDto
     */
    memberId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessDto
     */
    initialConfigured?: boolean;
}
/**
 * 
 * @export
 * @interface BusinessLocationDto
 */
export interface BusinessLocationDto {
    /**
     * 
     * @type {number}
     * @memberof BusinessLocationDto
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessLocationDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessLocationDto
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessLocationDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessLocationDto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessLocationDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessLocationDto
     */
    zip?: string;
}
/**
 * 
 * @export
 * @interface CompleteMemberDto
 */
export interface CompleteMemberDto {
    /**
     * 
     * @type {MemberDto}
     * @memberof CompleteMemberDto
     */
    member?: MemberDto;
    /**
     * 
     * @type {Array<BusinessDto>}
     * @memberof CompleteMemberDto
     */
    businesses?: Array<BusinessDto>;
}
/**
 * 
 * @export
 * @interface ConnectedAccountDto
 */
export interface ConnectedAccountDto {
    /**
     * 
     * @type {boolean}
     * @memberof ConnectedAccountDto
     */
    exists?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectedAccountDto
     */
    detailsSubmitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectedAccountDto
     */
    chargesEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectedAccountDto
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface CouponCodeDto
 */
export interface CouponCodeDto {
    /**
     * 
     * @type {string}
     * @memberof CouponCodeDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponCodeDto
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponCodeDto
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponCodeDto
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof CouponCodeDto
     */
    type?: CouponCodeDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CouponCodeDto
     */
    available?: number;
    /**
     * 
     * @type {number}
     * @memberof CouponCodeDto
     */
    used?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CouponCodeDto
     */
    active?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CouponCodeDtoTypeEnum {
    VALUE = 'VALUE',
    PERCENT = 'PERCENT'
}

/**
 * 
 * @export
 * @interface CustomTax
 */
export interface CustomTax {
    /**
     * 
     * @type {string}
     * @memberof CustomTax
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTax
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomTax
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomTax
     */
    type?: CustomTaxTypeEnum;
    /**
     * 
     * @type {Business}
     * @memberof CustomTax
     */
    business?: Business;
    /**
     * 
     * @type {string}
     * @memberof CustomTax
     */
    createdDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTax
     */
    removed?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomTaxTypeEnum {
    VALUE = 'VALUE',
    PERCENT = 'PERCENT'
}

/**
 * 
 * @export
 * @interface CustomTaxDto
 */
export interface CustomTaxDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTaxDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTaxDto
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomTaxDto
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomTaxDto
     */
    type?: CustomTaxDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomTaxDtoTypeEnum {
    VALUE = 'VALUE',
    PERCENT = 'PERCENT'
}

/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    lastOrderDate?: string;
    /**
     * 
     * @type {Array<OrderDto>}
     * @memberof CustomerDto
     */
    orders?: Array<OrderDto>;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    businessId?: string;
}
/**
 * 
 * @export
 * @interface DashboardDataDto
 */
export interface DashboardDataDto {
    /**
     * 
     * @type {BusinessDto}
     * @memberof DashboardDataDto
     */
    business?: BusinessDto;
    /**
     * 
     * @type {Array<MenuDto>}
     * @memberof DashboardDataDto
     */
    menus?: Array<MenuDto>;
    /**
     * 
     * @type {number}
     * @memberof DashboardDataDto
     */
    memberStatus?: number;
}
/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    id?: number;
    /**
     * 
     * @type {Member}
     * @memberof Email
     */
    member?: Member;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    type?: EmailTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EmailTypeEnum {
    WARNING = 'TRIAL_WARNING',
    ENDED = 'TRIAL_ENDED'
}

/**
 * 
 * @export
 * @interface ExportObject
 */
export interface ExportObject {
    /**
     * 
     * @type {number}
     * @memberof ExportObject
     */
    memberId?: number;
    /**
     * 
     * @type {Array<PublicBusinessDto>}
     * @memberof ExportObject
     */
    locations?: Array<PublicBusinessDto>;
}
/**
 * 
 * @export
 * @interface FullRevisionDto
 */
export interface FullRevisionDto {
    /**
     * 
     * @type {number}
     * @memberof FullRevisionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FullRevisionDto
     */
    business?: string;
    /**
     * 
     * @type {string}
     * @memberof FullRevisionDto
     */
    menus?: string;
    /**
     * 
     * @type {string}
     * @memberof FullRevisionDto
     */
    publishedMenus?: string;
    /**
     * 
     * @type {string}
     * @memberof FullRevisionDto
     */
    businessId?: string;
    /**
     * 
     * @type {string}
     * @memberof FullRevisionDto
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof FullRevisionDto
     */
    savedByMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof FullRevisionDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof FullRevisionDto
     */
    hash?: string;
}
/**
 * 
 * @export
 * @interface HoursDto
 */
export interface HoursDto {
    /**
     * 
     * @type {string}
     * @memberof HoursDto
     */
    hours?: string;
    /**
     * 
     * @type {number}
     * @memberof HoursDto
     */
    offset?: number;
}
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    friendlyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    type?: ImageTypeEnum;
    /**
     * 
     * @type {Member}
     * @memberof Image
     */
    member?: Member;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ImageTypeEnum {
    MenuItem = 'MenuItem',
    BusinessLogo = 'BusinessLogo',
    BusinessCover = 'BusinessCover',
    BusinessHero = 'BusinessHero',
    MenuItemIcon = 'MenuItemIcon'
}

/**
 * 
 * @export
 * @interface ImageDto
 */
export interface ImageDto {
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    friendlyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    type?: ImageDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    source?: ImageDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ImageDtoTypeEnum {
    MenuItem = 'MenuItem',
    BusinessLogo = 'BusinessLogo',
    BusinessCover = 'BusinessCover',
    BusinessHero = 'BusinessHero',
    MenuItemIcon = 'MenuItemIcon'
}
/**
    * @export
    * @enum {string}
    */
export enum ImageDtoSourceEnum {
    MyBrand = 'MyBrand',
    Ordering = 'Ordering'
}

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    file: any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    file: any;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject2
     */
    file: any;
}
/**
 * 
 * @export
 * @interface LandingPageDto
 */
export interface LandingPageDto {
    /**
     * 
     * @type {string}
     * @memberof LandingPageDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof LandingPageDto
     */
    memberId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LandingPageDto
     */
    removed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LandingPageDto
     */
    published?: boolean;
}
/**
 * 
 * @export
 * @interface LightCouponCodeDto
 */
export interface LightCouponCodeDto {
    /**
     * 
     * @type {string}
     * @memberof LightCouponCodeDto
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof LightCouponCodeDto
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof LightCouponCodeDto
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof LightCouponCodeDto
     */
    type?: LightCouponCodeDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LightCouponCodeDtoTypeEnum {
    VALUE = 'VALUE',
    PERCENT = 'PERCENT'
}

/**
 * 
 * @export
 * @interface LightDashboardDataDto
 */
export interface LightDashboardDataDto {
    /**
     * 
     * @type {number}
     * @memberof LightDashboardDataDto
     */
    viewCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LightDashboardDataDto
     */
    menuCount?: number;
}
/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    internalId?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    facebook?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    restaurantId?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    brandId?: string;
    /**
     * 
     * @type {Array<BrandLogoDto>}
     * @memberof LocationDto
     */
    brandLogos?: Array<BrandLogoDto>;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    type?: LocationDtoTypeEnum;
    /**
     * 
     * @type {Array<MemberDto>}
     * @memberof LocationDto
     */
    managers?: Array<MemberDto>;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDto
     */
    removed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    designCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    qrCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    lpCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    omCount?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    createdDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDto
     */
    primary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDto
     */
    disabled?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum LocationDtoTypeEnum {
    Restaurant = 'Restaurant',
    Spa = 'Spa',
    Dispensary = 'Dispensary',
    Designer = 'Designer',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface LogoutDto
 */
export interface LogoutDto {
    /**
     * 
     * @type {string}
     * @memberof LogoutDto
     */
    tkn?: string;
}
/**
 * 
 * @export
 * @interface MQGetOMStatusResponse
 */
export interface MQGetOMStatusResponse {
    /**
     * 
     * @type {Array<MemberBusinessStatusDto>}
     * @memberof MQGetOMStatusResponse
     */
    statuses?: Array<MemberBusinessStatusDto>;
}
/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    updatedDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    removed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    notificationEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    paymentConnectedAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    paymentCustomerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    state?: MemberStateEnum;
    /**
     * 
     * @type {Array<MemberRole>}
     * @memberof Member
     */
    roles?: Array<MemberRole>;
    /**
     * 
     * @type {Array<Business>}
     * @memberof Member
     */
    businesses?: Array<Business>;
    /**
     * 
     * @type {Array<Email>}
     * @memberof Member
     */
    sentEmails?: Array<Email>;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberStateEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DELETED = 'DELETED',
    LOCKED = 'LOCKED'
}

/**
 * 
 * @export
 * @interface MemberBusinessStatusDto
 */
export interface MemberBusinessStatusDto {
    /**
     * 
     * @type {string}
     * @memberof MemberBusinessStatusDto
     */
    businessId?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberBusinessStatusDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberBusinessStatusDto
     */
    status?: MemberBusinessStatusDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof MemberBusinessStatusDto
     */
    views?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberBusinessStatusDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberBusinessStatusDto
     */
    publishedDateMillis?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberBusinessStatusDto
     */
    lastUpdateMillis?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberBusinessStatusDto
     */
    menuCount?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberBusinessStatusDto
     */
    itemCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MemberBusinessStatusDto
     */
    removed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberBusinessStatusDto
     */
    inUse?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberBusinessStatusDto
     */
    inUseInitials?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberBusinessStatusDtoStatusEnum {
    PUBLISHED = 'PUBLISHED',
    UNPUBLISHED = 'UNPUBLISHED'
}

/**
 * 
 * @export
 * @interface MemberColorDto
 */
export interface MemberColorDto {
    /**
     * 
     * @type {string}
     * @memberof MemberColorDto
     */
    colorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberColorDto
     */
    memberId?: number;
}
/**
 * 
 * @export
 * @interface MemberDto
 */
export interface MemberDto {
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    notificationEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberDto
     */
    admin?: boolean;
}
/**
 * 
 * @export
 * @interface MemberIconDto
 */
export interface MemberIconDto {
    /**
     * 
     * @type {string}
     * @memberof MemberIconDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberIconDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberIconDto
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberIconDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberIconDto
     */
    visitorId?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberIconDto
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberIconDto
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberIconDto
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberIconDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberIconDto
     */
    altText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberIconDto
     */
    deleted?: boolean;
}
/**
 * 
 * @export
 * @interface MemberImageDto
 */
export interface MemberImageDto {
    /**
     * 
     * @type {string}
     * @memberof MemberImageDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImageDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImageDto
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImageDto
     */
    visitorId?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberImageDto
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberImageDto
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberImageDto
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImageDto
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberImageDto
     */
    altText?: string;
}
/**
 * 
 * @export
 * @interface MemberRole
 */
export interface MemberRole {
    /**
     * 
     * @type {string}
     * @memberof MemberRole
     */
    type?: MemberRoleTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MemberRole
     */
    id?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberRoleTypeEnum {
    OWNER = 'OWNER',
    CLIENT = 'CLIENT',
    ADMIN = 'ADMIN'
}

/**
 * 
 * @export
 * @interface MemberStatusDto
 */
export interface MemberStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof MemberStatusDto
     */
    authenticated?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberStatusDto
     */
    notificationSound?: boolean;
    /**
     * 
     * @type {MemberDto}
     * @memberof MemberStatusDto
     */
    member?: MemberDto;
    /**
     * 
     * @type {boolean}
     * @memberof MemberStatusDto
     */
    owner?: boolean;
}
/**
 * 
 * @export
 * @interface Menu
 */
export interface Menu {
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    updatedDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Menu
     */
    removed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    friendlyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    details?: string;
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof Menu
     */
    nodes?: string;
    /**
     * 
     * @type {number}
     * @memberof Menu
     */
    viewCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Menu
     */
    active?: boolean;
    /**
     * 
     * @type {PublishedMenu}
     * @memberof Menu
     */
    published?: PublishedMenu;
    /**
     * 
     * @type {Business}
     * @memberof Menu
     */
    business?: Business;
    /**
     * 
     * @type {number}
     * @memberof Menu
     */
    sequence?: number;
}
/**
 * 
 * @export
 * @interface MenuDto
 */
export interface MenuDto {
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    friendlyId?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    details?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    nodes?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuDto
     */
    viewCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDto
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDto
     */
    removed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    businessId?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuDto
     */
    sequence?: number;
}
/**
 * 
 * @export
 * @interface MenuSequenceDto
 */
export interface MenuSequenceDto {
    /**
     * 
     * @type {string}
     * @memberof MenuSequenceDto
     */
    menuId?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuSequenceDto
     */
    sequence?: number;
}
/**
 * 
 * @export
 * @interface MenuUrlDto
 */
export interface MenuUrlDto {
    /**
     * 
     * @type {string}
     * @memberof MenuUrlDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuUrlDto
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface NotificationCountDto
 */
export interface NotificationCountDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationCountDto
     */
    notifications?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationCountDto
     */
    newOrders?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationCountDto
     */
    inProgressOrders?: number;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    status?: NotificationDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    scope?: NotificationDtoScopeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationDtoStatusEnum {
    NEW = 'NEW',
    OLD = 'OLD',
    ARCHIVED = 'ARCHIVED'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationDtoScopeEnum {
    ORDER = 'ORDER'
}

/**
 * 
 * @export
 * @interface OrderChangedDto
 */
export interface OrderChangedDto {
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof OrderChangedDto
     */
    items?: Array<OrderItemDto>;
    /**
     * 
     * @type {number}
     * @memberof OrderChangedDto
     */
    subtotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChangedDto
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChangedDto
     */
    tipValue?: number;
    /**
     * 
     * @type {Array<OrderTaxDto>}
     * @memberof OrderChangedDto
     */
    taxes?: Array<OrderTaxDto>;
    /**
     * 
     * @type {LightCouponCodeDto}
     * @memberof OrderChangedDto
     */
    coupon?: LightCouponCodeDto;
    /**
     * 
     * @type {string}
     * @memberof OrderChangedDto
     */
    orderId?: string;
}
/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    friendlyId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    addressDetails?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    status?: OrderDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    payment?: OrderDtoPaymentEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    delivery?: OrderDtoDeliveryEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    subtotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    deliveryFee?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    deliveryDay?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    deliveryTime?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    pickUpTime?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    currency?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    tipValue?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    estimatedTime?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    onlinePaymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    allergies?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    submitDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    inAdvanceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    updatedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    createdDate?: string;
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof OrderDto
     */
    items?: Array<OrderItemDto>;
    /**
     * 
     * @type {Array<OrderTaxDto>}
     * @memberof OrderDto
     */
    taxes?: Array<OrderTaxDto>;
    /**
     * 
     * @type {LightCouponCodeDto}
     * @memberof OrderDto
     */
    coupon?: LightCouponCodeDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    menuId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    menuName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    businessId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    businessUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDto
     */
    edited?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum OrderDtoStatusEnum {
    DRAFT = 'DRAFT',
    NEW = 'NEW',
    PROGRESS = 'PROGRESS',
    READY = 'READY',
    DELIVERY = 'DELIVERY',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED'
}
/**
    * @export
    * @enum {string}
    */
export enum OrderDtoPaymentEnum {
    CASH = 'CASH',
    POS = 'POS',
    ONLINECC = 'ONLINE_CC',
    NONE = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum OrderDtoDeliveryEnum {
    DELIVERY = 'DELIVERY',
    PICKUP = 'PICKUP',
    NONE = 'NONE'
}

/**
 * 
 * @export
 * @interface OrderItemDto
 */
export interface OrderItemDto {
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    menuItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    size?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    modifiers?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    menuId?: string;
}
/**
 * 
 * @export
 * @interface OrderItemModifierDto
 */
export interface OrderItemModifierDto {
    /**
     * 
     * @type {string}
     * @memberof OrderItemModifierDto
     */
    modifierId?: string;
    /**
     * 
     * @type {Array<OrderItemModifierSelectionDto>}
     * @memberof OrderItemModifierDto
     */
    selections?: Array<OrderItemModifierSelectionDto>;
}
/**
 * 
 * @export
 * @interface OrderItemModifierSelectionDto
 */
export interface OrderItemModifierSelectionDto {
    /**
     * 
     * @type {string}
     * @memberof OrderItemModifierSelectionDto
     */
    selectionId?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemModifierSelectionDto
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface OrderTaxDto
 */
export interface OrderTaxDto {
    /**
     * 
     * @type {string}
     * @memberof OrderTaxDto
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderTaxDto
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface OrderTotalDto
 */
export interface OrderTotalDto {
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof OrderTotalDto
     */
    items?: Array<OrderItemDto>;
}
/**
 * 
 * @export
 * @interface PageDto
 */
export interface PageDto {
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof PageDto
     */
    visitCount?: number;
    /**
     * 
     * @type {string}
     * @memberof PageDto
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface PublicBusinessCfg
 */
export interface PublicBusinessCfg {
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    facebook?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    instagram?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    cover?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessCfg
     */
    about?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessCfg
     */
    showBusinessName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessCfg
     */
    showAddress?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessCfg
     */
    showPhone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessCfg
     */
    showWebsite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessCfg
     */
    showEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessCfg
     */
    showTwitter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessCfg
     */
    showFacebook?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessCfg
     */
    showInstagram?: boolean;
}
/**
 * 
 * @export
 * @interface PublicBusinessDto
 */
export interface PublicBusinessDto {
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessDto
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof PublicBusinessDto
     */
    viewCount?: number;
    /**
     * 
     * @type {PublicBusinessCfg}
     * @memberof PublicBusinessDto
     */
    config?: PublicBusinessCfg;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessDto
     */
    published?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBusinessDto
     */
    migrated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PublicBusinessDto
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessDto
     */
    createdDate?: string;
    /**
     * 
     * @type {Array<PublicMenuDto>}
     * @memberof PublicBusinessDto
     */
    menus?: Array<PublicMenuDto>;
    /**
     * 
     * @type {string}
     * @memberof PublicBusinessDto
     */
    customUrl?: string;
}
/**
 * 
 * @export
 * @interface PublicMenuDto
 */
export interface PublicMenuDto {
    /**
     * 
     * @type {string}
     * @memberof PublicMenuDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PublicMenuDto
     */
    viewCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicMenuDto
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicMenuDto
     */
    removed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PublicMenuDto
     */
    sequence?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicMenuDto
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicMenuDto
     */
    createdDate?: string;
}
/**
 * 
 * @export
 * @interface PublishedBusinessDataDto
 */
export interface PublishedBusinessDataDto {
    /**
     * 
     * @type {BusinessDto}
     * @memberof PublishedBusinessDataDto
     */
    business?: BusinessDto;
    /**
     * 
     * @type {WebsiteConfig}
     * @memberof PublishedBusinessDataDto
     */
    config?: WebsiteConfig;
    /**
     * 
     * @type {Array<MenuDto>}
     * @memberof PublishedBusinessDataDto
     */
    menus?: Array<MenuDto>;
    /**
     * 
     * @type {string}
     * @memberof PublishedBusinessDataDto
     */
    stripeAccountId?: string;
}
/**
 * 
 * @export
 * @interface PublishedMenu
 */
export interface PublishedMenu {
    /**
     * 
     * @type {string}
     * @memberof PublishedMenu
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishedMenu
     */
    updatedDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublishedMenu
     */
    removed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublishedMenu
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishedMenu
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishedMenu
     */
    details?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishedMenu
     */
    currency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublishedMenu
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublishedMenu
     */
    nodes?: string;
    /**
     * 
     * @type {number}
     * @memberof PublishedMenu
     */
    sequence?: number;
    /**
     * 
     * @type {Menu}
     * @memberof PublishedMenu
     */
    menu?: Menu;
}
/**
 * 
 * @export
 * @interface QRCodeDto
 */
export interface QRCodeDto {
    /**
     * 
     * @type {string}
     * @memberof QRCodeDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof QRCodeDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof QRCodeDto
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof QRCodeDto
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof QRCodeDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof QRCodeDto
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface RatingDto
 */
export interface RatingDto {
    /**
     * 
     * @type {string}
     * @memberof RatingDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof RatingDto
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof RatingDto
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof RatingDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RatingDto
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RatingDto
     */
    approved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RatingDto
     */
    createdDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RatingDto
     */
    removed?: boolean;
}
/**
 * 
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterDto
     */
    preferredMemberId?: number;
}
/**
 * 
 * @export
 * @interface RevisionDto
 */
export interface RevisionDto {
    /**
     * 
     * @type {number}
     * @memberof RevisionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionDto
     */
    savedByMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionDto
     */
    createdDate?: string;
}
/**
 * 
 * @export
 * @interface TimberIconDto
 */
export interface TimberIconDto {
    /**
     * 
     * @type {string}
     * @memberof TimberIconDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberIconDto
     */
    iconKey?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberIconDto
     */
    fill?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberIconDto
     */
    altText?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberIconDto
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface TimberItemDto
 */
export interface TimberItemDto {
    /**
     * 
     * @type {string}
     * @memberof TimberItemDto
     */
    designId?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberItemDto
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberItemDto
     */
    objectName?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberItemDto
     */
    objectNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberItemDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberItemDto
     */
    description?: string;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof TimberItemDto
     */
    prices?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {string}
     * @memberof TimberItemDto
     */
    extras?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberItemDto
     */
    bin?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberItemDto
     */
    binHeader?: string;
    /**
     * 
     * @type {Array<TimberIconDto>}
     * @memberof TimberItemDto
     */
    menuIcons?: Array<TimberIconDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TimberItemDto
     */
    selected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimberItemDto
     */
    visible?: boolean;
}
/**
 * 
 * @export
 * @interface TimberMenuDto
 */
export interface TimberMenuDto {
    /**
     * 
     * @type {string}
     * @memberof TimberMenuDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberMenuDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TimberMenuDto
     */
    size?: string;
}
/**
 * 
 * @export
 * @interface TimberMenuMoResponse
 */
export interface TimberMenuMoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TimberMenuMoResponse
     */
    hasNext?: boolean;
    /**
     * 
     * @type {Array<TimberMenuDto>}
     * @memberof TimberMenuMoResponse
     */
    list?: Array<TimberMenuDto>;
}
/**
 * 
 * @export
 * @interface TransferFilesDto
 */
export interface TransferFilesDto {
    /**
     * 
     * @type {number}
     * @memberof TransferFilesDto
     */
    fromLocationId?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferFilesDto
     */
    toLocationId?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferFilesDto
     */
    fromOwnerId?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferFilesDto
     */
    toOwnerId?: number;
}
/**
 * 
 * @export
 * @interface UpdateNotificationsDto
 */
export interface UpdateNotificationsDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsDto
     */
    email?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsDto
     */
    sound?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsDto
     */
    browser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationsDto
     */
    sms?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateOrderStatusDto
 */
export interface UpdateOrderStatusDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStatusDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStatusDto
     */
    status?: UpdateOrderStatusDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStatusDto
     */
    estimatedTime?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStatusDto
     */
    details?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateOrderStatusDtoStatusEnum {
    DRAFT = 'DRAFT',
    NEW = 'NEW',
    PROGRESS = 'PROGRESS',
    READY = 'READY',
    DELIVERY = 'DELIVERY',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED'
}

/**
 * 
 * @export
 * @interface WebsiteConfig
 */
export interface WebsiteConfig {
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    logoSize?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    logoBorderRadius?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    cover?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    coverSize?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    coverBorderRadius?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    header?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    headerColor?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    headerSize?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    headerWeight?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    headerAlign?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    titleColor?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    titleSize?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    titleWeight?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    titleStyle?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    titleAlign?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    subtitleColor?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    subtitleSize?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    subtitleWeight?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    subtitleStyle?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    subtitleAlign?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    heroSize?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    heroBgColor?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    heroBgImage?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    heroBorder?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    heroBorderRadius?: string;
    /**
     * 
     * @type {Array<ActionButton>}
     * @memberof WebsiteConfig
     */
    top?: Array<ActionButton>;
    /**
     * 
     * @type {Array<ActionButton>}
     * @memberof WebsiteConfig
     */
    nav?: Array<ActionButton>;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    itemsColumnType?: WebsiteConfigItemsColumnTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WebsiteConfig
     */
    sectionsAlign?: WebsiteConfigSectionsAlignEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum WebsiteConfigItemsColumnTypeEnum {
    ONE = 'ONE',
    TWO = 'TWO',
    THREE = 'THREE'
}
/**
    * @export
    * @enum {string}
    */
export enum WebsiteConfigSectionsAlignEnum {
    LEFT = 'LEFT',
    CENTER = 'CENTER',
    RIGHT = 'RIGHT'
}

/**
 * 
 * @export
 * @interface WebsiteDto
 */
export interface WebsiteDto {
    /**
     * 
     * @type {string}
     * @memberof WebsiteDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteDto
     */
    googleAnalyticsTag?: string;
    /**
     * 
     * @type {WebsiteConfig}
     * @memberof WebsiteDto
     */
    config?: WebsiteConfig;
}

/**
 * AdminResourceApi - axios parameter creator
 * @export
 */
export const AdminResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminDashboard: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/private/dashboard`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMember: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMember.');
            }
            const localVarPath = `/v1/private/members/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMember: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling searchMember.');
            }
            const localVarPath = `/v1/private/members`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminResourceApi - functional programming interface
 * @export
 */
export const AdminResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminDashboard(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDashboardDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getAdminDashboard(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMember(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteMemberDto>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).getMember(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMember(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberDto>>> {
            const localVarAxiosArgs = await AdminResourceApiAxiosParamCreator(configuration).searchMember(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminResourceApi - factory interface
 * @export
 */
export const AdminResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminDashboard(options?: any): AxiosPromise<AdminDashboardDto> {
            return AdminResourceApiFp(configuration).getAdminDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMember(memberId: number, options?: any): AxiosPromise<CompleteMemberDto> {
            return AdminResourceApiFp(configuration).getMember(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMember(key: string, options?: any): AxiosPromise<Array<MemberDto>> {
            return AdminResourceApiFp(configuration).searchMember(key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminResourceApi - object-oriented interface
 * @export
 * @class AdminResourceApi
 * @extends {BaseAPI}
 */
export class AdminResourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getAdminDashboard(options?: any) {
        return AdminResourceApiFp(this.configuration).getAdminDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public getMember(memberId: number, options?: any) {
        return AdminResourceApiFp(this.configuration).getMember(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminResourceApi
     */
    public searchMember(key: string, options?: any) {
        return AdminResourceApiFp(this.configuration).searchMember(key, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AppResourceApi - axios parameter creator
 * @export
 */
export const AppResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/public/app/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppResourceApi - functional programming interface
 * @export
 */
export const AppResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppConfigDto>> {
            const localVarAxiosArgs = await AppResourceApiAxiosParamCreator(configuration).getAppConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AppResourceApi - factory interface
 * @export
 */
export const AppResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppConfig(options?: any): AxiosPromise<AppConfigDto> {
            return AppResourceApiFp(configuration).getAppConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppResourceApi - object-oriented interface
 * @export
 * @class AppResourceApi
 * @extends {BaseAPI}
 */
export class AppResourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppResourceApi
     */
    public getAppConfig(options?: any) {
        return AppResourceApiFp(this.configuration).getAppConfig(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AuthResourceApi - axios parameter creator
 * @export
 */
export const AuthResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginDto: LoginDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            if (loginDto === null || loginDto === undefined) {
                throw new RequiredError('loginDto','Required parameter loginDto was null or undefined when calling login.');
            }
            const localVarPath = `/v1/public/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginDto !== undefined ? loginDto : {}) : (loginDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogoutDto} logoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (logoutDto: LogoutDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'logoutDto' is not null or undefined
            if (logoutDto === null || logoutDto === undefined) {
                throw new RequiredError('logoutDto','Required parameter logoutDto was null or undefined when calling logout.');
            }
            const localVarPath = `/v1/public/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof logoutDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(logoutDto !== undefined ? logoutDto : {}) : (logoutDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerDto: RegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerDto' is not null or undefined
            if (registerDto === null || registerDto === undefined) {
                throw new RequiredError('registerDto','Required parameter registerDto was null or undefined when calling register.');
            }
            const localVarPath = `/v1/public/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof registerDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(registerDto !== undefined ? registerDto : {}) : (registerDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (loginDto: LoginDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            if (loginDto === null || loginDto === undefined) {
                throw new RequiredError('loginDto','Required parameter loginDto was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/v1/public/resetPassword`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginDto !== undefined ? loginDto : {}) : (loginDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthResourceApi - functional programming interface
 * @export
 */
export const AuthResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginDto: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponseDto>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).login(loginDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LogoutDto} logoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(logoutDto: LogoutDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).logout(logoutDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerDto: RegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponseDto>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).register(registerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(loginDto: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthResourceApiAxiosParamCreator(configuration).resetPassword(loginDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthResourceApi - factory interface
 * @export
 */
export const AuthResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDto: LoginDto, options?: any): AxiosPromise<AuthResponseDto> {
            return AuthResourceApiFp(configuration).login(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogoutDto} logoutDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(logoutDto: LogoutDto, options?: any): AxiosPromise<void> {
            return AuthResourceApiFp(configuration).logout(logoutDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerDto: RegisterDto, options?: any): AxiosPromise<AuthResponseDto> {
            return AuthResourceApiFp(configuration).register(registerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(loginDto: LoginDto, options?: any): AxiosPromise<void> {
            return AuthResourceApiFp(configuration).resetPassword(loginDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthResourceApi - object-oriented interface
 * @export
 * @class AuthResourceApi
 * @extends {BaseAPI}
 */
export class AuthResourceApi extends BaseAPI {
    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public login(loginDto: LoginDto, options?: any) {
        return AuthResourceApiFp(this.configuration).login(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogoutDto} logoutDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public logout(logoutDto: LogoutDto, options?: any) {
        return AuthResourceApiFp(this.configuration).logout(logoutDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterDto} registerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public register(registerDto: RegisterDto, options?: any) {
        return AuthResourceApiFp(this.configuration).register(registerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResourceApi
     */
    public resetPassword(loginDto: LoginDto, options?: any) {
        return AuthResourceApiFp(this.configuration).resetPassword(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BrandResourceApi - axios parameter creator
 * @export
 */
export const BrandResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandColors: async (locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling getBrandColors.');
            }
            const localVarPath = `/v1/brands/{locationId}/colors`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandLogos: async (locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling getBrandLogos.');
            }
            const localVarPath = `/v1/brands/{locationId}/logos`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {string} token 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBrandLogo: async (locationId: number, token: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling uploadBrandLogo.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling uploadBrandLogo.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadBrandLogo.');
            }
            const localVarPath = `/v1/brands/{locationId}/upload/logo`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandResourceApi - functional programming interface
 * @export
 */
export const BrandResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandColors(locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandColorDto>>> {
            const localVarAxiosArgs = await BrandResourceApiAxiosParamCreator(configuration).getBrandColors(locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandLogos(locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandLogoDto>>> {
            const localVarAxiosArgs = await BrandResourceApiAxiosParamCreator(configuration).getBrandLogos(locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {string} token 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBrandLogo(locationId: number, token: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandLogoDto>> {
            const localVarAxiosArgs = await BrandResourceApiAxiosParamCreator(configuration).uploadBrandLogo(locationId, token, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BrandResourceApi - factory interface
 * @export
 */
export const BrandResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandColors(locationId: number, options?: any): AxiosPromise<Array<BrandColorDto>> {
            return BrandResourceApiFp(configuration).getBrandColors(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandLogos(locationId: number, options?: any): AxiosPromise<Array<BrandLogoDto>> {
            return BrandResourceApiFp(configuration).getBrandLogos(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {string} token 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBrandLogo(locationId: number, token: string, file: any, options?: any): AxiosPromise<BrandLogoDto> {
            return BrandResourceApiFp(configuration).uploadBrandLogo(locationId, token, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandResourceApi - object-oriented interface
 * @export
 * @class BrandResourceApi
 * @extends {BaseAPI}
 */
export class BrandResourceApi extends BaseAPI {
    /**
     * 
     * @param {number} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandResourceApi
     */
    public getBrandColors(locationId: number, options?: any) {
        return BrandResourceApiFp(this.configuration).getBrandColors(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandResourceApi
     */
    public getBrandLogos(locationId: number, options?: any) {
        return BrandResourceApiFp(this.configuration).getBrandLogos(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} locationId 
     * @param {string} token 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandResourceApi
     */
    public uploadBrandLogo(locationId: number, token: string, file: any, options?: any) {
        return BrandResourceApiFp(this.configuration).uploadBrandLogo(locationId, token, file, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BusinessResourceApi - axios parameter creator
 * @export
 */
export const BusinessResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {RatingDto} ratingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRating: async (id: string, ratingDto: RatingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addRating.');
            }
            // verify required parameter 'ratingDto' is not null or undefined
            if (ratingDto === null || ratingDto === undefined) {
                throw new RequiredError('ratingDto','Required parameter ratingDto was null or undefined when calling addRating.');
            }
            const localVarPath = `/v1/public/businesses/{id}/ratings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof ratingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(ratingDto !== undefined ? ratingDto : {}) : (ratingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canPublishChanges: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canPublishChanges.');
            }
            const localVarPath = `/v1/businesses/{id}/publish/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkURLValidity: async (url: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError('url','Required parameter url was null or undefined when calling checkURLValidity.');
            }
            const localVarPath = `/v1/businesses/url/validate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countBusinessOrders: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling countBusinessOrders.');
            }
            const localVarPath = `/v1/businesses/{id}/orders/count`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusiness: async (id: number, token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteBusiness.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling deleteBusiness.');
            }
            const localVarPath = `/v1/public/businesses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableBusinesses: async (token: string, requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling disableBusinesses.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling disableBusinesses.');
            }
            const localVarPath = `/v1/public/businesses/disable`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {number} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableBusinesses: async (token: string, body: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling enableBusinesses.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling enableBusinesses.');
            }
            const localVarPath = `/v1/public/businesses/enable`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessLocation: async (token: string, locationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getBusinessLocation.');
            }
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling getBusinessLocation.');
            }
            const localVarPath = `/v1/public/businesses/{locationId}/location`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessLocations: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getBusinessLocations.');
            }
            const localVarPath = `/v1/public/businesses/location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {'TODAY' | 'ADVANCE' | 'ALL'} tab 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessOrders: async (id: string, tab: 'TODAY' | 'ADVANCE' | 'ALL', page?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBusinessOrders.');
            }
            // verify required parameter 'tab' is not null or undefined
            if (tab === null || tab === undefined) {
                throw new RequiredError('tab','Required parameter tab was null or undefined when calling getBusinessOrders.');
            }
            const localVarPath = `/v1/businesses/{id}/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tab !== undefined) {
                localVarQueryParameter['tab'] = tab;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} revisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessRevision: async (id: string, revisionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBusinessRevision.');
            }
            // verify required parameter 'revisionId' is not null or undefined
            if (revisionId === null || revisionId === undefined) {
                throw new RequiredError('revisionId','Required parameter revisionId was null or undefined when calling getBusinessRevision.');
            }
            const localVarPath = `/v1/businesses/{id}/revisions/{revisionId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"revisionId"}}`, encodeURIComponent(String(revisionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessRevisions: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBusinessRevisions.');
            }
            const localVarPath = `/v1/businesses/{id}/revisions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationDetails: async (locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling getLocationDetails.');
            }
            const localVarPath = `/v1/businesses/{locationId}/location`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [email] 
         * @param {number} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMQStatusForMember: async (token: string, email?: string, locationId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getMQStatusForMember.');
            }
            const localVarPath = `/v1/public/businesses/mqstatus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicInfo: async (memberId: number, businessId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getPublicInfo.');
            }
            // verify required parameter 'businessId' is not null or undefined
            if (businessId === null || businessId === undefined) {
                throw new RequiredError('businessId','Required parameter businessId was null or undefined when calling getPublicInfo.');
            }
            const localVarPath = `/v1/public/business`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (businessId !== undefined) {
                localVarQueryParameter['businessId'] = businessId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicLocationDetails: async (locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling getPublicLocationDetails.');
            }
            const localVarPath = `/v1/public/businesses/{locationId}/publicLocation`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicRatings: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPublicRatings.');
            }
            const localVarPath = `/v1/public/businesses/{id}/ratings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedBusinessAndMenus: async (url: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError('url','Required parameter url was null or undefined when calling getPublishedBusinessAndMenus.');
            }
            const localVarPath = `/v1/public/businesses/{url}/menus`
                .replace(`{${"url"}}`, encodeURIComponent(String(url)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatings: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRatings.');
            }
            const localVarPath = `/v1/businesses/{id}/ratings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForBusiness: async (id: string, token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getStatusForBusiness.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getStatusForBusiness.');
            }
            const localVarPath = `/v1/public/businesses/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForMember: async (token: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getStatusForMember.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling getStatusForMember.');
            }
            const localVarPath = `/v1/public/businesses/status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForMember1: async (token: string, memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getStatusForMember1.');
            }
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getStatusForMember1.');
            }
            const localVarPath = `/v1/public/businesses/statusById`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseViewCount1: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling increaseViewCount1.');
            }
            const localVarPath = `/v1/public/businesses/{id}/viewCount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {BusinessDto} businessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBusiness: async (id: string, businessDto: BusinessDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchBusiness.');
            }
            // verify required parameter 'businessDto' is not null or undefined
            if (businessDto === null || businessDto === undefined) {
                throw new RequiredError('businessDto','Required parameter businessDto was null or undefined when calling patchBusiness.');
            }
            const localVarPath = `/v1/businesses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof businessDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessDto !== undefined ? businessDto : {}) : (businessDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RatingDto} ratingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRating: async (id: string, ratingDto: RatingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchRating.');
            }
            // verify required parameter 'ratingDto' is not null or undefined
            if (ratingDto === null || ratingDto === undefined) {
                throw new RequiredError('ratingDto','Required parameter ratingDto was null or undefined when calling patchRating.');
            }
            const localVarPath = `/v1/public/businesses/{id}/ratings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof ratingDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(ratingDto !== undefined ? ratingDto : {}) : (ratingDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishChanges: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling publishChanges.');
            }
            const localVarPath = `/v1/businesses/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/public/businesses/ie5bdLqBFU2HKGak`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRevision: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveRevision.');
            }
            const localVarPath = `/v1/businesses/{id}/revision`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProduct: async (id: string, query: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling searchProduct.');
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query','Required parameter query was null or undefined when calling searchProduct.');
            }
            const localVarPath = `/v1/businesses/{id}/search`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} token 
         * @param {TransferFilesDto} transferFilesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferBusiness: async (id: number, token: string, transferFilesDto: TransferFilesDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transferBusiness.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling transferBusiness.');
            }
            // verify required parameter 'transferFilesDto' is not null or undefined
            if (transferFilesDto === null || transferFilesDto === undefined) {
                throw new RequiredError('transferFilesDto','Required parameter transferFilesDto was null or undefined when calling transferBusiness.');
            }
            const localVarPath = `/v1/public/businesses/{id}/transfer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof transferFilesDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(transferFilesDto !== undefined ? transferFilesDto : {}) : (transferFilesDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishChanges: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unpublishChanges.');
            }
            const localVarPath = `/v1/businesses/{id}/unpublish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} open 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAvailability: async (id: string, open: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAvailability.');
            }
            // verify required parameter 'open' is not null or undefined
            if (open === null || open === undefined) {
                throw new RequiredError('open','Required parameter open was null or undefined when calling updateAvailability.');
            }
            const localVarPath = `/v1/businesses/{id}/open`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (open !== undefined) {
                localVarQueryParameter['open'] = open;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {BusinessDto} businessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusiness: async (id: string, businessDto: BusinessDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBusiness.');
            }
            // verify required parameter 'businessDto' is not null or undefined
            if (businessDto === null || businessDto === undefined) {
                throw new RequiredError('businessDto','Required parameter businessDto was null or undefined when calling updateBusiness.');
            }
            const localVarPath = `/v1/businesses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof businessDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(businessDto !== undefined ? businessDto : {}) : (businessDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {HoursDto} hoursDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHours: async (id: string, hoursDto: HoursDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateHours.');
            }
            // verify required parameter 'hoursDto' is not null or undefined
            if (hoursDto === null || hoursDto === undefined) {
                throw new RequiredError('hoursDto','Required parameter hoursDto was null or undefined when calling updateHours.');
            }
            const localVarPath = `/v1/businesses/{id}/hours`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof hoursDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(hoursDto !== undefined ? hoursDto : {}) : (hoursDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {LocationDto} locationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (locationId: number, locationDto: LocationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling updateLocation.');
            }
            // verify required parameter 'locationDto' is not null or undefined
            if (locationDto === null || locationDto === undefined) {
                throw new RequiredError('locationDto','Required parameter locationDto was null or undefined when calling updateLocation.');
            }
            const localVarPath = `/v1/businesses/{locationId}/location`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof locationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(locationDto !== undefined ? locationDto : {}) : (locationDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateNotificationsDto} updateNotificationsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications: async (id: string, updateNotificationsDto: UpdateNotificationsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNotifications.');
            }
            // verify required parameter 'updateNotificationsDto' is not null or undefined
            if (updateNotificationsDto === null || updateNotificationsDto === undefined) {
                throw new RequiredError('updateNotificationsDto','Required parameter updateNotificationsDto was null or undefined when calling updateNotifications.');
            }
            const localVarPath = `/v1/businesses/{id}/notifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateNotificationsDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateNotificationsDto !== undefined ? updateNotificationsDto : {}) : (updateNotificationsDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUrl: async (id: string, url: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUrl.');
            }
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError('url','Required parameter url was null or undefined when calling updateUrl.');
            }
            const localVarPath = `/v1/businesses/{id}/url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} revisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useBusinessRevision: async (id: string, revisionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling useBusinessRevision.');
            }
            // verify required parameter 'revisionId' is not null or undefined
            if (revisionId === null || revisionId === undefined) {
                throw new RequiredError('revisionId','Required parameter revisionId was null or undefined when calling useBusinessRevision.');
            }
            const localVarPath = `/v1/businesses/{id}/revisions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (revisionId !== undefined) {
                localVarQueryParameter['revisionId'] = revisionId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessResourceApi - functional programming interface
 * @export
 */
export const BusinessResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {RatingDto} ratingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRating(id: string, ratingDto: RatingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).addRating(id, ratingDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canPublishChanges(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).canPublishChanges(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkURLValidity(url: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).checkURLValidity(url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countBusinessOrders(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).countBusinessOrders(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBusiness(id: number, token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).deleteBusiness(id, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableBusinesses(token: string, requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).disableBusinesses(token, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {number} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableBusinesses(token: string, body: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).enableBusinesses(token, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessLocation(token: string, locationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessLocationDto>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getBusinessLocation(token, locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessLocations(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessLocationDto>>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getBusinessLocations(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {'TODAY' | 'ADVANCE' | 'ALL'} tab 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessOrders(id: string, tab: 'TODAY' | 'ADVANCE' | 'ALL', page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getBusinessOrders(id, tab, page, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} revisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessRevision(id: string, revisionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullRevisionDto>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getBusinessRevision(id, revisionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessRevisions(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RevisionDto>>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getBusinessRevisions(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationDetails(locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getLocationDetails(locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [email] 
         * @param {number} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMQStatusForMember(token: string, email?: string, locationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MQGetOMStatusResponse>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getMQStatusForMember(token, email, locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicInfo(memberId: number, businessId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessCfg>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getPublicInfo(memberId, businessId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicLocationDetails(locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getPublicLocationDetails(locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicRatings(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RatingDto>>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getPublicRatings(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedBusinessAndMenus(url: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishedBusinessDataDto>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getPublishedBusinessAndMenus(url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRatings(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RatingDto>>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getRatings(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusForBusiness(id: string, token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberBusinessStatusDto>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getStatusForBusiness(id, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusForMember(token: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberBusinessStatusDto>>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getStatusForMember(token, email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusForMember1(token: string, memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberBusinessStatusDto>>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).getStatusForMember1(token, memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async increaseViewCount1(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).increaseViewCount1(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {BusinessDto} businessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchBusiness(id: string, businessDto: BusinessDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).patchBusiness(id, businessDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RatingDto} ratingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchRating(id: string, ratingDto: RatingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).patchRating(id, ratingDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishChanges(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessDto>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).publishChanges(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExportObject>>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).retrieveAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveRevision(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).saveRevision(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProduct(id: string, query: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuDto>>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).searchProduct(id, query, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} token 
         * @param {TransferFilesDto} transferFilesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferBusiness(id: number, token: string, transferFilesDto: TransferFilesDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).transferBusiness(id, token, transferFilesDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpublishChanges(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).unpublishChanges(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} open 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAvailability(id: string, open: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).updateAvailability(id, open, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {BusinessDto} businessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBusiness(id: string, businessDto: BusinessDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessDto>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).updateBusiness(id, businessDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {HoursDto} hoursDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHours(id: string, hoursDto: HoursDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).updateHours(id, hoursDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {LocationDto} locationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(locationId: number, locationDto: LocationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDto>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).updateLocation(locationId, locationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateNotificationsDto} updateNotificationsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifications(id: string, updateNotificationsDto: UpdateNotificationsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).updateNotifications(id, updateNotificationsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUrl(id: string, url: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).updateUrl(id, url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} revisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useBusinessRevision(id: string, revisionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BusinessResourceApiAxiosParamCreator(configuration).useBusinessRevision(id, revisionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BusinessResourceApi - factory interface
 * @export
 */
export const BusinessResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {RatingDto} ratingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRating(id: string, ratingDto: RatingDto, options?: any): AxiosPromise<boolean> {
            return BusinessResourceApiFp(configuration).addRating(id, ratingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canPublishChanges(id: string, options?: any): AxiosPromise<boolean> {
            return BusinessResourceApiFp(configuration).canPublishChanges(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkURLValidity(url: string, options?: any): AxiosPromise<boolean> {
            return BusinessResourceApiFp(configuration).checkURLValidity(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countBusinessOrders(id: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return BusinessResourceApiFp(configuration).countBusinessOrders(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusiness(id: number, token: string, options?: any): AxiosPromise<void> {
            return BusinessResourceApiFp(configuration).deleteBusiness(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableBusinesses(token: string, requestBody: Array<number>, options?: any): AxiosPromise<number> {
            return BusinessResourceApiFp(configuration).disableBusinesses(token, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {number} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableBusinesses(token: string, body: number, options?: any): AxiosPromise<number> {
            return BusinessResourceApiFp(configuration).enableBusinesses(token, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessLocation(token: string, locationId: string, options?: any): AxiosPromise<BusinessLocationDto> {
            return BusinessResourceApiFp(configuration).getBusinessLocation(token, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessLocations(token: string, options?: any): AxiosPromise<Array<BusinessLocationDto>> {
            return BusinessResourceApiFp(configuration).getBusinessLocations(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {'TODAY' | 'ADVANCE' | 'ALL'} tab 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessOrders(id: string, tab: 'TODAY' | 'ADVANCE' | 'ALL', page?: number, options?: any): AxiosPromise<Array<OrderDto>> {
            return BusinessResourceApiFp(configuration).getBusinessOrders(id, tab, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} revisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessRevision(id: string, revisionId: number, options?: any): AxiosPromise<FullRevisionDto> {
            return BusinessResourceApiFp(configuration).getBusinessRevision(id, revisionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessRevisions(id: string, options?: any): AxiosPromise<Array<RevisionDto>> {
            return BusinessResourceApiFp(configuration).getBusinessRevisions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationDetails(locationId: number, options?: any): AxiosPromise<LocationDto> {
            return BusinessResourceApiFp(configuration).getLocationDetails(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} [email] 
         * @param {number} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMQStatusForMember(token: string, email?: string, locationId?: number, options?: any): AxiosPromise<MQGetOMStatusResponse> {
            return BusinessResourceApiFp(configuration).getMQStatusForMember(token, email, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicInfo(memberId: number, businessId: number, options?: any): AxiosPromise<BusinessCfg> {
            return BusinessResourceApiFp(configuration).getPublicInfo(memberId, businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicLocationDetails(locationId: number, options?: any): AxiosPromise<LocationDto> {
            return BusinessResourceApiFp(configuration).getPublicLocationDetails(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicRatings(id: string, options?: any): AxiosPromise<Array<RatingDto>> {
            return BusinessResourceApiFp(configuration).getPublicRatings(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedBusinessAndMenus(url: string, options?: any): AxiosPromise<PublishedBusinessDataDto> {
            return BusinessResourceApiFp(configuration).getPublishedBusinessAndMenus(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatings(id: string, options?: any): AxiosPromise<Array<RatingDto>> {
            return BusinessResourceApiFp(configuration).getRatings(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForBusiness(id: string, token: string, options?: any): AxiosPromise<MemberBusinessStatusDto> {
            return BusinessResourceApiFp(configuration).getStatusForBusiness(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForMember(token: string, email: string, options?: any): AxiosPromise<Array<MemberBusinessStatusDto>> {
            return BusinessResourceApiFp(configuration).getStatusForMember(token, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForMember1(token: string, memberId: number, options?: any): AxiosPromise<Array<MemberBusinessStatusDto>> {
            return BusinessResourceApiFp(configuration).getStatusForMember1(token, memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseViewCount1(id: string, options?: any): AxiosPromise<void> {
            return BusinessResourceApiFp(configuration).increaseViewCount1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {BusinessDto} businessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBusiness(id: string, businessDto: BusinessDto, options?: any): AxiosPromise<boolean> {
            return BusinessResourceApiFp(configuration).patchBusiness(id, businessDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RatingDto} ratingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRating(id: string, ratingDto: RatingDto, options?: any): AxiosPromise<boolean> {
            return BusinessResourceApiFp(configuration).patchRating(id, ratingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishChanges(id: string, options?: any): AxiosPromise<BusinessDto> {
            return BusinessResourceApiFp(configuration).publishChanges(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAll(options?: any): AxiosPromise<Array<ExportObject>> {
            return BusinessResourceApiFp(configuration).retrieveAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRevision(id: string, options?: any): AxiosPromise<void> {
            return BusinessResourceApiFp(configuration).saveRevision(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProduct(id: string, query: string, options?: any): AxiosPromise<Array<MenuDto>> {
            return BusinessResourceApiFp(configuration).searchProduct(id, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} token 
         * @param {TransferFilesDto} transferFilesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferBusiness(id: number, token: string, transferFilesDto: TransferFilesDto, options?: any): AxiosPromise<void> {
            return BusinessResourceApiFp(configuration).transferBusiness(id, token, transferFilesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishChanges(id: string, options?: any): AxiosPromise<void> {
            return BusinessResourceApiFp(configuration).unpublishChanges(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} open 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAvailability(id: string, open: boolean, options?: any): AxiosPromise<boolean> {
            return BusinessResourceApiFp(configuration).updateAvailability(id, open, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {BusinessDto} businessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusiness(id: string, businessDto: BusinessDto, options?: any): AxiosPromise<BusinessDto> {
            return BusinessResourceApiFp(configuration).updateBusiness(id, businessDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {HoursDto} hoursDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHours(id: string, hoursDto: HoursDto, options?: any): AxiosPromise<boolean> {
            return BusinessResourceApiFp(configuration).updateHours(id, hoursDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {LocationDto} locationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(locationId: number, locationDto: LocationDto, options?: any): AxiosPromise<LocationDto> {
            return BusinessResourceApiFp(configuration).updateLocation(locationId, locationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateNotificationsDto} updateNotificationsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications(id: string, updateNotificationsDto: UpdateNotificationsDto, options?: any): AxiosPromise<boolean> {
            return BusinessResourceApiFp(configuration).updateNotifications(id, updateNotificationsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUrl(id: string, url: string, options?: any): AxiosPromise<boolean> {
            return BusinessResourceApiFp(configuration).updateUrl(id, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} revisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useBusinessRevision(id: string, revisionId: number, options?: any): AxiosPromise<void> {
            return BusinessResourceApiFp(configuration).useBusinessRevision(id, revisionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessResourceApi - object-oriented interface
 * @export
 * @class BusinessResourceApi
 * @extends {BaseAPI}
 */
export class BusinessResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {RatingDto} ratingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public addRating(id: string, ratingDto: RatingDto, options?: any) {
        return BusinessResourceApiFp(this.configuration).addRating(id, ratingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public canPublishChanges(id: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).canPublishChanges(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public checkURLValidity(url: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).checkURLValidity(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public countBusinessOrders(id: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).countBusinessOrders(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public deleteBusiness(id: number, token: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).deleteBusiness(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public disableBusinesses(token: string, requestBody: Array<number>, options?: any) {
        return BusinessResourceApiFp(this.configuration).disableBusinesses(token, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {number} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public enableBusinesses(token: string, body: number, options?: any) {
        return BusinessResourceApiFp(this.configuration).enableBusinesses(token, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getBusinessLocation(token: string, locationId: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).getBusinessLocation(token, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getBusinessLocations(token: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).getBusinessLocations(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {'TODAY' | 'ADVANCE' | 'ALL'} tab 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getBusinessOrders(id: string, tab: 'TODAY' | 'ADVANCE' | 'ALL', page?: number, options?: any) {
        return BusinessResourceApiFp(this.configuration).getBusinessOrders(id, tab, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} revisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getBusinessRevision(id: string, revisionId: number, options?: any) {
        return BusinessResourceApiFp(this.configuration).getBusinessRevision(id, revisionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getBusinessRevisions(id: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).getBusinessRevisions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getLocationDetails(locationId: number, options?: any) {
        return BusinessResourceApiFp(this.configuration).getLocationDetails(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {string} [email] 
     * @param {number} [locationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getMQStatusForMember(token: string, email?: string, locationId?: number, options?: any) {
        return BusinessResourceApiFp(this.configuration).getMQStatusForMember(token, email, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {number} businessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getPublicInfo(memberId: number, businessId: number, options?: any) {
        return BusinessResourceApiFp(this.configuration).getPublicInfo(memberId, businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getPublicLocationDetails(locationId: number, options?: any) {
        return BusinessResourceApiFp(this.configuration).getPublicLocationDetails(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getPublicRatings(id: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).getPublicRatings(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getPublishedBusinessAndMenus(url: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).getPublishedBusinessAndMenus(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getRatings(id: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).getRatings(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getStatusForBusiness(id: string, token: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).getStatusForBusiness(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getStatusForMember(token: string, email: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).getStatusForMember(token, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public getStatusForMember1(token: string, memberId: number, options?: any) {
        return BusinessResourceApiFp(this.configuration).getStatusForMember1(token, memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public increaseViewCount1(id: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).increaseViewCount1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {BusinessDto} businessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public patchBusiness(id: string, businessDto: BusinessDto, options?: any) {
        return BusinessResourceApiFp(this.configuration).patchBusiness(id, businessDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RatingDto} ratingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public patchRating(id: string, ratingDto: RatingDto, options?: any) {
        return BusinessResourceApiFp(this.configuration).patchRating(id, ratingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public publishChanges(id: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).publishChanges(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public retrieveAll(options?: any) {
        return BusinessResourceApiFp(this.configuration).retrieveAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public saveRevision(id: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).saveRevision(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public searchProduct(id: string, query: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).searchProduct(id, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} token 
     * @param {TransferFilesDto} transferFilesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public transferBusiness(id: number, token: string, transferFilesDto: TransferFilesDto, options?: any) {
        return BusinessResourceApiFp(this.configuration).transferBusiness(id, token, transferFilesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public unpublishChanges(id: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).unpublishChanges(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} open 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public updateAvailability(id: string, open: boolean, options?: any) {
        return BusinessResourceApiFp(this.configuration).updateAvailability(id, open, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {BusinessDto} businessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public updateBusiness(id: string, businessDto: BusinessDto, options?: any) {
        return BusinessResourceApiFp(this.configuration).updateBusiness(id, businessDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {HoursDto} hoursDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public updateHours(id: string, hoursDto: HoursDto, options?: any) {
        return BusinessResourceApiFp(this.configuration).updateHours(id, hoursDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} locationId 
     * @param {LocationDto} locationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public updateLocation(locationId: number, locationDto: LocationDto, options?: any) {
        return BusinessResourceApiFp(this.configuration).updateLocation(locationId, locationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateNotificationsDto} updateNotificationsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public updateNotifications(id: string, updateNotificationsDto: UpdateNotificationsDto, options?: any) {
        return BusinessResourceApiFp(this.configuration).updateNotifications(id, updateNotificationsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public updateUrl(id: string, url: string, options?: any) {
        return BusinessResourceApiFp(this.configuration).updateUrl(id, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} revisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessResourceApi
     */
    public useBusinessRevision(id: string, revisionId: number, options?: any) {
        return BusinessResourceApiFp(this.configuration).useBusinessRevision(id, revisionId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CouponCodeResourceApi - axios parameter creator
 * @export
 */
export const CouponCodeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {CouponCodeDto} couponCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoupon: async (businessId: string, couponCodeDto: CouponCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            if (businessId === null || businessId === undefined) {
                throw new RequiredError('businessId','Required parameter businessId was null or undefined when calling createCoupon.');
            }
            // verify required parameter 'couponCodeDto' is not null or undefined
            if (couponCodeDto === null || couponCodeDto === undefined) {
                throw new RequiredError('couponCodeDto','Required parameter couponCodeDto was null or undefined when calling createCoupon.');
            }
            const localVarPath = `/v1/businesses/{businessId}/coupons`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof couponCodeDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(couponCodeDto !== undefined ? couponCodeDto : {}) : (couponCodeDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessCoupons: async (businessId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            if (businessId === null || businessId === undefined) {
                throw new RequiredError('businessId','Required parameter businessId was null or undefined when calling getBusinessCoupons.');
            }
            const localVarPath = `/v1/businesses/{businessId}/coupons`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCoupon: async (businessId: string, couponId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            if (businessId === null || businessId === undefined) {
                throw new RequiredError('businessId','Required parameter businessId was null or undefined when calling removeCoupon.');
            }
            // verify required parameter 'couponId' is not null or undefined
            if (couponId === null || couponId === undefined) {
                throw new RequiredError('couponId','Required parameter couponId was null or undefined when calling removeCoupon.');
            }
            const localVarPath = `/v1/businesses/{businessId}/coupons/{couponId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"couponId"}}`, encodeURIComponent(String(couponId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} couponId 
         * @param {CouponCodeDto} couponCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoupon: async (businessId: string, couponId: string, couponCodeDto: CouponCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            if (businessId === null || businessId === undefined) {
                throw new RequiredError('businessId','Required parameter businessId was null or undefined when calling updateCoupon.');
            }
            // verify required parameter 'couponId' is not null or undefined
            if (couponId === null || couponId === undefined) {
                throw new RequiredError('couponId','Required parameter couponId was null or undefined when calling updateCoupon.');
            }
            // verify required parameter 'couponCodeDto' is not null or undefined
            if (couponCodeDto === null || couponCodeDto === undefined) {
                throw new RequiredError('couponCodeDto','Required parameter couponCodeDto was null or undefined when calling updateCoupon.');
            }
            const localVarPath = `/v1/businesses/{businessId}/coupons/{couponId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"couponId"}}`, encodeURIComponent(String(couponId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof couponCodeDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(couponCodeDto !== undefined ? couponCodeDto : {}) : (couponCodeDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponCodeResourceApi - functional programming interface
 * @export
 */
export const CouponCodeResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {CouponCodeDto} couponCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCoupon(businessId: string, couponCodeDto: CouponCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponCodeDto>> {
            const localVarAxiosArgs = await CouponCodeResourceApiAxiosParamCreator(configuration).createCoupon(businessId, couponCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessCoupons(businessId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CouponCodeDto>>> {
            const localVarAxiosArgs = await CouponCodeResourceApiAxiosParamCreator(configuration).getBusinessCoupons(businessId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCoupon(businessId: string, couponId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await CouponCodeResourceApiAxiosParamCreator(configuration).removeCoupon(businessId, couponId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} couponId 
         * @param {CouponCodeDto} couponCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCoupon(businessId: string, couponId: string, couponCodeDto: CouponCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponCodeDto>> {
            const localVarAxiosArgs = await CouponCodeResourceApiAxiosParamCreator(configuration).updateCoupon(businessId, couponId, couponCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CouponCodeResourceApi - factory interface
 * @export
 */
export const CouponCodeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {CouponCodeDto} couponCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoupon(businessId: string, couponCodeDto: CouponCodeDto, options?: any): AxiosPromise<CouponCodeDto> {
            return CouponCodeResourceApiFp(configuration).createCoupon(businessId, couponCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessCoupons(businessId: string, options?: any): AxiosPromise<Array<CouponCodeDto>> {
            return CouponCodeResourceApiFp(configuration).getBusinessCoupons(businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} couponId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCoupon(businessId: string, couponId: string, options?: any): AxiosPromise<boolean> {
            return CouponCodeResourceApiFp(configuration).removeCoupon(businessId, couponId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} couponId 
         * @param {CouponCodeDto} couponCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCoupon(businessId: string, couponId: string, couponCodeDto: CouponCodeDto, options?: any): AxiosPromise<CouponCodeDto> {
            return CouponCodeResourceApiFp(configuration).updateCoupon(businessId, couponId, couponCodeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CouponCodeResourceApi - object-oriented interface
 * @export
 * @class CouponCodeResourceApi
 * @extends {BaseAPI}
 */
export class CouponCodeResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} businessId 
     * @param {CouponCodeDto} couponCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponCodeResourceApi
     */
    public createCoupon(businessId: string, couponCodeDto: CouponCodeDto, options?: any) {
        return CouponCodeResourceApiFp(this.configuration).createCoupon(businessId, couponCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponCodeResourceApi
     */
    public getBusinessCoupons(businessId: string, options?: any) {
        return CouponCodeResourceApiFp(this.configuration).getBusinessCoupons(businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} couponId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponCodeResourceApi
     */
    public removeCoupon(businessId: string, couponId: string, options?: any) {
        return CouponCodeResourceApiFp(this.configuration).removeCoupon(businessId, couponId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} couponId 
     * @param {CouponCodeDto} couponCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponCodeResourceApi
     */
    public updateCoupon(businessId: string, couponId: string, couponCodeDto: CouponCodeDto, options?: any) {
        return CouponCodeResourceApiFp(this.configuration).updateCoupon(businessId, couponId, couponCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CustomerResourceApi - axios parameter creator
 * @export
 */
export const CustomerResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomerDto} customerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (customerDto: CustomerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerDto' is not null or undefined
            if (customerDto === null || customerDto === undefined) {
                throw new RequiredError('customerDto','Required parameter customerDto was null or undefined when calling createCustomer.');
            }
            const localVarPath = `/v1/customers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof customerDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customerDto !== undefined ? customerDto : {}) : (customerDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessCustomers: async (businessId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            if (businessId === null || businessId === undefined) {
                throw new RequiredError('businessId','Required parameter businessId was null or undefined when calling getBusinessCustomers.');
            }
            const localVarPath = `/v1/customers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (businessId !== undefined) {
                localVarQueryParameter['businessId'] = businessId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCustomer.');
            }
            const localVarPath = `/v1/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCustomers: async (businessId: string, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            if (businessId === null || businessId === undefined) {
                throw new RequiredError('businessId','Required parameter businessId was null or undefined when calling searchCustomers.');
            }
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling searchCustomers.');
            }
            const localVarPath = `/v1/customers/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (businessId !== undefined) {
                localVarQueryParameter['businessId'] = businessId;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerResourceApi - functional programming interface
 * @export
 */
export const CustomerResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomerDto} customerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(customerDto: CustomerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await CustomerResourceApiAxiosParamCreator(configuration).createCustomer(customerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessCustomers(businessId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerDto>>> {
            const localVarAxiosArgs = await CustomerResourceApiAxiosParamCreator(configuration).getBusinessCustomers(businessId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await CustomerResourceApiAxiosParamCreator(configuration).getCustomer(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCustomers(businessId: string, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerDto>>> {
            const localVarAxiosArgs = await CustomerResourceApiAxiosParamCreator(configuration).searchCustomers(businessId, key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerResourceApi - factory interface
 * @export
 */
export const CustomerResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CustomerDto} customerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(customerDto: CustomerDto, options?: any): AxiosPromise<CustomerDto> {
            return CustomerResourceApiFp(configuration).createCustomer(customerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessCustomers(businessId: string, options?: any): AxiosPromise<Array<CustomerDto>> {
            return CustomerResourceApiFp(configuration).getBusinessCustomers(businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer(id: string, options?: any): AxiosPromise<CustomerDto> {
            return CustomerResourceApiFp(configuration).getCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCustomers(businessId: string, key: string, options?: any): AxiosPromise<Array<CustomerDto>> {
            return CustomerResourceApiFp(configuration).searchCustomers(businessId, key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerResourceApi - object-oriented interface
 * @export
 * @class CustomerResourceApi
 * @extends {BaseAPI}
 */
export class CustomerResourceApi extends BaseAPI {
    /**
     * 
     * @param {CustomerDto} customerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerResourceApi
     */
    public createCustomer(customerDto: CustomerDto, options?: any) {
        return CustomerResourceApiFp(this.configuration).createCustomer(customerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerResourceApi
     */
    public getBusinessCustomers(businessId: string, options?: any) {
        return CustomerResourceApiFp(this.configuration).getBusinessCustomers(businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerResourceApi
     */
    public getCustomer(id: string, options?: any) {
        return CustomerResourceApiFp(this.configuration).getCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerResourceApi
     */
    public searchCustomers(businessId: string, key: string, options?: any) {
        return CustomerResourceApiFp(this.configuration).searchCustomers(businessId, key, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ImageResourceApi - axios parameter creator
 * @export
 */
export const ImageResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getImage.');
            }
            const localVarPath = `/v1/public/images`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageResourceApi - functional programming interface
 * @export
 */
export const ImageResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImage(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await ImageResourceApiAxiosParamCreator(configuration).getImage(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ImageResourceApi - factory interface
 * @export
 */
export const ImageResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(key: string, options?: any): AxiosPromise<Array<string>> {
            return ImageResourceApiFp(configuration).getImage(key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageResourceApi - object-oriented interface
 * @export
 * @class ImageResourceApi
 * @extends {BaseAPI}
 */
export class ImageResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageResourceApi
     */
    public getImage(key: string, options?: any) {
        return ImageResourceApiFp(this.configuration).getImage(key, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * InternalResourceApi - axios parameter creator
 * @export
 */
export const InternalResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberMenus: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberMenus.');
            }
            const localVarPath = `/v1/public/members/menus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberMenusCount: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberMenusCount.');
            }
            const localVarPath = `/v1/public/members/menus/count`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberMenusUrl: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberMenusUrl.');
            }
            const localVarPath = `/v1/public/members/menus/url`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalResourceApi - functional programming interface
 * @export
 */
export const InternalResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberMenus(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuUrlDto>>> {
            const localVarAxiosArgs = await InternalResourceApiAxiosParamCreator(configuration).getMemberMenus(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberMenusCount(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await InternalResourceApiAxiosParamCreator(configuration).getMemberMenusCount(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberMenusUrl(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await InternalResourceApiAxiosParamCreator(configuration).getMemberMenusUrl(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InternalResourceApi - factory interface
 * @export
 */
export const InternalResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberMenus(memberId: number, options?: any): AxiosPromise<Array<MenuUrlDto>> {
            return InternalResourceApiFp(configuration).getMemberMenus(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberMenusCount(memberId: number, options?: any): AxiosPromise<number> {
            return InternalResourceApiFp(configuration).getMemberMenusCount(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberMenusUrl(memberId: number, options?: any): AxiosPromise<string> {
            return InternalResourceApiFp(configuration).getMemberMenusUrl(memberId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalResourceApi - object-oriented interface
 * @export
 * @class InternalResourceApi
 * @extends {BaseAPI}
 */
export class InternalResourceApi extends BaseAPI {
    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalResourceApi
     */
    public getMemberMenus(memberId: number, options?: any) {
        return InternalResourceApiFp(this.configuration).getMemberMenus(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalResourceApi
     */
    public getMemberMenusCount(memberId: number, options?: any) {
        return InternalResourceApiFp(this.configuration).getMemberMenusCount(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalResourceApi
     */
    public getMemberMenusUrl(memberId: number, options?: any) {
        return InternalResourceApiFp(this.configuration).getMemberMenusUrl(memberId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LocaleResourceApi - axios parameter creator
 * @export
 */
export const LocaleResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations: async (lng: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lng' is not null or undefined
            if (lng === null || lng === undefined) {
                throw new RequiredError('lng','Required parameter lng was null or undefined when calling getTranslations.');
            }
            const localVarPath = `/v1/public/locales/{lng}.json`
                .replace(`{${"lng"}}`, encodeURIComponent(String(lng)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocaleResourceApi - functional programming interface
 * @export
 */
export const LocaleResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslations(lng: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await LocaleResourceApiAxiosParamCreator(configuration).getTranslations(lng, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocaleResourceApi - factory interface
 * @export
 */
export const LocaleResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(lng: string, options?: any): AxiosPromise<string> {
            return LocaleResourceApiFp(configuration).getTranslations(lng, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocaleResourceApi - object-oriented interface
 * @export
 * @class LocaleResourceApi
 * @extends {BaseAPI}
 */
export class LocaleResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} lng 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleResourceApi
     */
    public getTranslations(lng: string, options?: any) {
        return LocaleResourceApiFp(this.configuration).getTranslations(lng, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoginTokenResourceApi - axios parameter creator
 * @export
 */
export const LoginTokenResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanExpiredToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/public/login-token/clean`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginTokenResourceApi - functional programming interface
 * @export
 */
export const LoginTokenResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanExpiredToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LoginTokenResourceApiAxiosParamCreator(configuration).cleanExpiredToken(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoginTokenResourceApi - factory interface
 * @export
 */
export const LoginTokenResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanExpiredToken(options?: any): AxiosPromise<void> {
            return LoginTokenResourceApiFp(configuration).cleanExpiredToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginTokenResourceApi - object-oriented interface
 * @export
 * @class LoginTokenResourceApi
 * @extends {BaseAPI}
 */
export class LoginTokenResourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginTokenResourceApi
     */
    public cleanExpiredToken(options?: any) {
        return LoginTokenResourceApiFp(this.configuration).cleanExpiredToken(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MemberResourceApi - axios parameter creator
 * @export
 */
export const MemberResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} memberId 
         * @param {'ORDER'} [scope] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearNotifications: async (memberId: number, scope?: 'ORDER', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling clearNotifications.');
            }
            const localVarPath = `/v1/members/{memberId}/notifications`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} oldLocationId 
         * @param {number} newLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMenusToLocation: async (memberId: number, oldLocationId: number, newLocationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling copyMenusToLocation.');
            }
            // verify required parameter 'oldLocationId' is not null or undefined
            if (oldLocationId === null || oldLocationId === undefined) {
                throw new RequiredError('oldLocationId','Required parameter oldLocationId was null or undefined when calling copyMenusToLocation.');
            }
            // verify required parameter 'newLocationId' is not null or undefined
            if (newLocationId === null || newLocationId === undefined) {
                throw new RequiredError('newLocationId','Required parameter newLocationId was null or undefined when calling copyMenusToLocation.');
            }
            const localVarPath = `/v1/members/{memberId}/menus/copyToLocation`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (oldLocationId !== undefined) {
                localVarQueryParameter['oldLocationId'] = oldLocationId;
            }

            if (newLocationId !== undefined) {
                localVarQueryParameter['newLocationId'] = newLocationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} iconId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberIcon: async (iconId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iconId' is not null or undefined
            if (iconId === null || iconId === undefined) {
                throw new RequiredError('iconId','Required parameter iconId was null or undefined when calling deleteMemberIcon.');
            }
            const localVarPath = `/v1/members/icons/{iconId}`
                .replace(`{${"iconId"}}`, encodeURIComponent(String(iconId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {string} [businessId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightMemberDashboard: async (memberId: number, businessId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getLightMemberDashboard.');
            }
            const localVarPath = `/v1/members/{memberId}/light-dashboard`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (businessId !== undefined) {
                localVarQueryParameter['businessId'] = businessId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberColors: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberColors.');
            }
            const localVarPath = `/v1/members/{memberId}/colors`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {string} [businessId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberDashboard: async (memberId: number, businessId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberDashboard.');
            }
            const localVarPath = `/v1/members/{memberId}/dashboard`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (businessId !== undefined) {
                localVarQueryParameter['businessId'] = businessId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberIcons: async (memberId: number, locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberIcons.');
            }
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling getMemberIcons.');
            }
            const localVarPath = `/v1/members/{memberId}/icons`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberImages: async (memberId: number, locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberImages.');
            }
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling getMemberImages.');
            }
            const localVarPath = `/v1/members/{memberId}/images`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberLandingPages: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberLandingPages.');
            }
            const localVarPath = `/v1/members/{memberId}/lps`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberLocations: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberLocations.');
            }
            const localVarPath = `/v1/members/{memberId}/locations`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberNotifications: async (memberId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberNotifications.');
            }
            const localVarPath = `/v1/members/{memberId}/notifications`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberUploads: async (memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMemberUploads.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getMemberUploads.');
            }
            const localVarPath = `/v1/members/{memberId}/uploads`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationCount: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getNotificationCount.');
            }
            const localVarPath = `/v1/members/{memberId}/notifications/count`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne: async (memberId: number, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getOne.');
            }
            const localVarPath = `/v1/members`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling getStatus.');
            }
            const localVarPath = `/v1/public/members/status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsOld: async (memberId: string, notificationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling markNotificationAsOld.');
            }
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling markNotificationAsOld.');
            }
            const localVarPath = `/v1/members/{memberId}/notifications/{notificationId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)))
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAccount: async (token: string, memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling resetAccount.');
            }
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling resetAccount.');
            }
            const localVarPath = `/v1/public/members/reset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<MemberIconDto>} memberIconDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMemberIcons: async (memberIconDto: Array<MemberIconDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberIconDto' is not null or undefined
            if (memberIconDto === null || memberIconDto === undefined) {
                throw new RequiredError('memberIconDto','Required parameter memberIconDto was null or undefined when calling saveMemberIcons.');
            }
            const localVarPath = `/v1/members/icons/save`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof memberIconDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(memberIconDto !== undefined ? memberIconDto : {}) : (memberIconDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<MemberImageDto>} memberImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMemberImages: async (memberImageDto: Array<MemberImageDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberImageDto' is not null or undefined
            if (memberImageDto === null || memberImageDto === undefined) {
                throw new RequiredError('memberImageDto','Required parameter memberImageDto was null or undefined when calling saveMemberImages.');
            }
            const localVarPath = `/v1/members/images/save`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof memberImageDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(memberImageDto !== undefined ? memberImageDto : {}) : (memberImageDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        silentRegister: async (registerDto: RegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerDto' is not null or undefined
            if (registerDto === null || registerDto === undefined) {
                throw new RequiredError('registerDto','Required parameter registerDto was null or undefined when calling silentRegister.');
            }
            const localVarPath = `/v1/public/members/silentRegister`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof registerDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(registerDto !== undefined ? registerDto : {}) : (registerDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
         * @param {number} locationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMemberIcon: async (memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', locationId: number, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling uploadMemberIcon.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling uploadMemberIcon.');
            }
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling uploadMemberIcon.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadMemberIcon.');
            }
            const localVarPath = `/v1/members/{memberId}/icon-uploads`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
         * @param {number} locationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMemberImage: async (memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', locationId: number, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling uploadMemberImage.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling uploadMemberImage.');
            }
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling uploadMemberImage.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadMemberImage.');
            }
            const localVarPath = `/v1/members/{memberId}/uploads`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberResourceApi - functional programming interface
 * @export
 */
export const MemberResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} memberId 
         * @param {'ORDER'} [scope] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearNotifications(memberId: number, scope?: 'ORDER', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).clearNotifications(memberId, scope, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} oldLocationId 
         * @param {number} newLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyMenusToLocation(memberId: number, oldLocationId: number, newLocationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).copyMenusToLocation(memberId, oldLocationId, newLocationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} iconId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMemberIcon(iconId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).deleteMemberIcon(iconId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {string} [businessId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLightMemberDashboard(memberId: number, businessId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightDashboardDataDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getLightMemberDashboard(memberId, businessId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberColors(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberColorDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMemberColors(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {string} [businessId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberDashboard(memberId: number, businessId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardDataDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMemberDashboard(memberId, businessId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberIcons(memberId: number, locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberIconDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMemberIcons(memberId, locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberImages(memberId: number, locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberImageDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMemberImages(memberId, locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberLandingPages(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LandingPageDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMemberLandingPages(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberLocations(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMemberLocations(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberNotifications(memberId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMemberNotifications(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberUploads(memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageDto>>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getMemberUploads(memberId, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationCount(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationCountDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getNotificationCount(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOne(memberId: number, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getOne(memberId, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberStatusDto>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).getStatus(locationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationAsOld(memberId: string, notificationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).markNotificationAsOld(memberId, notificationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAccount(token: string, memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).resetAccount(token, memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<MemberIconDto>} memberIconDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMemberIcons(memberIconDto: Array<MemberIconDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).saveMemberIcons(memberIconDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<MemberImageDto>} memberImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMemberImages(memberImageDto: Array<MemberImageDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).saveMemberImages(memberImageDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async silentRegister(registerDto: RegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).silentRegister(registerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
         * @param {number} locationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMemberIcon(memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', locationId: number, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).uploadMemberIcon(memberId, type, locationId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
         * @param {number} locationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMemberImage(memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', locationId: number, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
            const localVarAxiosArgs = await MemberResourceApiAxiosParamCreator(configuration).uploadMemberImage(memberId, type, locationId, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MemberResourceApi - factory interface
 * @export
 */
export const MemberResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} memberId 
         * @param {'ORDER'} [scope] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearNotifications(memberId: number, scope?: 'ORDER', options?: any): AxiosPromise<boolean> {
            return MemberResourceApiFp(configuration).clearNotifications(memberId, scope, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} oldLocationId 
         * @param {number} newLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMenusToLocation(memberId: number, oldLocationId: number, newLocationId: number, options?: any): AxiosPromise<Array<MenuDto>> {
            return MemberResourceApiFp(configuration).copyMenusToLocation(memberId, oldLocationId, newLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} iconId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberIcon(iconId: string, options?: any): AxiosPromise<boolean> {
            return MemberResourceApiFp(configuration).deleteMemberIcon(iconId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {string} [businessId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightMemberDashboard(memberId: number, businessId?: string, options?: any): AxiosPromise<LightDashboardDataDto> {
            return MemberResourceApiFp(configuration).getLightMemberDashboard(memberId, businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberColors(memberId: number, options?: any): AxiosPromise<Array<MemberColorDto>> {
            return MemberResourceApiFp(configuration).getMemberColors(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {string} [businessId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberDashboard(memberId: number, businessId?: string, options?: any): AxiosPromise<DashboardDataDto> {
            return MemberResourceApiFp(configuration).getMemberDashboard(memberId, businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberIcons(memberId: number, locationId: number, options?: any): AxiosPromise<Array<MemberIconDto>> {
            return MemberResourceApiFp(configuration).getMemberIcons(memberId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberImages(memberId: number, locationId: number, options?: any): AxiosPromise<Array<MemberImageDto>> {
            return MemberResourceApiFp(configuration).getMemberImages(memberId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberLandingPages(memberId: number, options?: any): AxiosPromise<Array<LandingPageDto>> {
            return MemberResourceApiFp(configuration).getMemberLandingPages(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberLocations(memberId: number, options?: any): AxiosPromise<Array<LocationDto>> {
            return MemberResourceApiFp(configuration).getMemberLocations(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberNotifications(memberId: string, options?: any): AxiosPromise<Array<NotificationDto>> {
            return MemberResourceApiFp(configuration).getMemberNotifications(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberUploads(memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', options?: any): AxiosPromise<Array<ImageDto>> {
            return MemberResourceApiFp(configuration).getMemberUploads(memberId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationCount(memberId: number, options?: any): AxiosPromise<NotificationCountDto> {
            return MemberResourceApiFp(configuration).getNotificationCount(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOne(memberId: number, type?: string, options?: any): AxiosPromise<MemberDto> {
            return MemberResourceApiFp(configuration).getOne(memberId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(locationId: number, options?: any): AxiosPromise<MemberStatusDto> {
            return MemberResourceApiFp(configuration).getStatus(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} memberId 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsOld(memberId: string, notificationId: string, options?: any): AxiosPromise<boolean> {
            return MemberResourceApiFp(configuration).markNotificationAsOld(memberId, notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAccount(token: string, memberId: number, options?: any): AxiosPromise<boolean> {
            return MemberResourceApiFp(configuration).resetAccount(token, memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<MemberIconDto>} memberIconDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMemberIcons(memberIconDto: Array<MemberIconDto>, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).saveMemberIcons(memberIconDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<MemberImageDto>} memberImageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMemberImages(memberImageDto: Array<MemberImageDto>, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).saveMemberImages(memberImageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        silentRegister(registerDto: RegisterDto, options?: any): AxiosPromise<void> {
            return MemberResourceApiFp(configuration).silentRegister(registerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
         * @param {number} locationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMemberIcon(memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', locationId: number, file: any, options?: any): AxiosPromise<Image> {
            return MemberResourceApiFp(configuration).uploadMemberIcon(memberId, type, locationId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
         * @param {number} locationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMemberImage(memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', locationId: number, file: any, options?: any): AxiosPromise<Image> {
            return MemberResourceApiFp(configuration).uploadMemberImage(memberId, type, locationId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberResourceApi - object-oriented interface
 * @export
 * @class MemberResourceApi
 * @extends {BaseAPI}
 */
export class MemberResourceApi extends BaseAPI {
    /**
     * 
     * @param {number} memberId 
     * @param {'ORDER'} [scope] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public clearNotifications(memberId: number, scope?: 'ORDER', options?: any) {
        return MemberResourceApiFp(this.configuration).clearNotifications(memberId, scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {number} oldLocationId 
     * @param {number} newLocationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public copyMenusToLocation(memberId: number, oldLocationId: number, newLocationId: number, options?: any) {
        return MemberResourceApiFp(this.configuration).copyMenusToLocation(memberId, oldLocationId, newLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} iconId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public deleteMemberIcon(iconId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).deleteMemberIcon(iconId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {string} [businessId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getLightMemberDashboard(memberId: number, businessId?: string, options?: any) {
        return MemberResourceApiFp(this.configuration).getLightMemberDashboard(memberId, businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMemberColors(memberId: number, options?: any) {
        return MemberResourceApiFp(this.configuration).getMemberColors(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {string} [businessId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMemberDashboard(memberId: number, businessId?: string, options?: any) {
        return MemberResourceApiFp(this.configuration).getMemberDashboard(memberId, businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {number} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMemberIcons(memberId: number, locationId: number, options?: any) {
        return MemberResourceApiFp(this.configuration).getMemberIcons(memberId, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {number} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMemberImages(memberId: number, locationId: number, options?: any) {
        return MemberResourceApiFp(this.configuration).getMemberImages(memberId, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMemberLandingPages(memberId: number, options?: any) {
        return MemberResourceApiFp(this.configuration).getMemberLandingPages(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMemberLocations(memberId: number, options?: any) {
        return MemberResourceApiFp(this.configuration).getMemberLocations(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMemberNotifications(memberId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).getMemberNotifications(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getMemberUploads(memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', options?: any) {
        return MemberResourceApiFp(this.configuration).getMemberUploads(memberId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getNotificationCount(memberId: number, options?: any) {
        return MemberResourceApiFp(this.configuration).getNotificationCount(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getOne(memberId: number, type?: string, options?: any) {
        return MemberResourceApiFp(this.configuration).getOne(memberId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public getStatus(locationId: number, options?: any) {
        return MemberResourceApiFp(this.configuration).getStatus(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} memberId 
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public markNotificationAsOld(memberId: string, notificationId: string, options?: any) {
        return MemberResourceApiFp(this.configuration).markNotificationAsOld(memberId, notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public resetAccount(token: string, memberId: number, options?: any) {
        return MemberResourceApiFp(this.configuration).resetAccount(token, memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<MemberIconDto>} memberIconDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public saveMemberIcons(memberIconDto: Array<MemberIconDto>, options?: any) {
        return MemberResourceApiFp(this.configuration).saveMemberIcons(memberIconDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<MemberImageDto>} memberImageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public saveMemberImages(memberImageDto: Array<MemberImageDto>, options?: any) {
        return MemberResourceApiFp(this.configuration).saveMemberImages(memberImageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterDto} registerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public silentRegister(registerDto: RegisterDto, options?: any) {
        return MemberResourceApiFp(this.configuration).silentRegister(registerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
     * @param {number} locationId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public uploadMemberIcon(memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', locationId: number, file: any, options?: any) {
        return MemberResourceApiFp(this.configuration).uploadMemberIcon(memberId, type, locationId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon'} type 
     * @param {number} locationId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberResourceApi
     */
    public uploadMemberImage(memberId: number, type: 'MenuItem' | 'BusinessLogo' | 'BusinessCover' | 'BusinessHero' | 'MenuItemIcon', locationId: number, file: any, options?: any) {
        return MemberResourceApiFp(this.configuration).uploadMemberImage(memberId, type, locationId, file, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MenuResourceApi - axios parameter creator
 * @export
 */
export const MenuResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MenuDto} menuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenu: async (menuDto: MenuDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuDto' is not null or undefined
            if (menuDto === null || menuDto === undefined) {
                throw new RequiredError('menuDto','Required parameter menuDto was null or undefined when calling createMenu.');
            }
            const localVarPath = `/v1/menus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof menuDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(menuDto !== undefined ? menuDto : {}) : (menuDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<MenuDto>} menuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenus: async (menuDto: Array<MenuDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuDto' is not null or undefined
            if (menuDto === null || menuDto === undefined) {
                throw new RequiredError('menuDto','Required parameter menuDto was null or undefined when calling createMenus.');
            }
            const localVarPath = `/v1/menus/multiple`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof menuDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(menuDto !== undefined ? menuDto : {}) : (menuDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} designId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesignMenuObjects: async (designId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'designId' is not null or undefined
            if (designId === null || designId === undefined) {
                throw new RequiredError('designId','Required parameter designId was null or undefined when calling getDesignMenuObjects.');
            }
            const localVarPath = `/v1/menus/timber/designs/{designId}/mo`
                .replace(`{${"designId"}}`, encodeURIComponent(String(designId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenu: async (menuId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuId' is not null or undefined
            if (menuId === null || menuId === undefined) {
                throw new RequiredError('menuId','Required parameter menuId was null or undefined when calling getMenu.');
            }
            const localVarPath = `/v1/menus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (menuId !== undefined) {
                localVarQueryParameter['menuId'] = menuId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenus: async (memberId: number, businessId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getMenus.');
            }
            // verify required parameter 'businessId' is not null or undefined
            if (businessId === null || businessId === undefined) {
                throw new RequiredError('businessId','Required parameter businessId was null or undefined when calling getMenus.');
            }
            const localVarPath = `/v1/public/menus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (businessId !== undefined) {
                localVarQueryParameter['businessId'] = businessId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [businessId] 
         * @param {string} [key] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimberMoMenus: async (businessId?: number, key?: string, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/menus/timber/mo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (businessId !== undefined) {
                localVarQueryParameter['businessId'] = businessId;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseViewCount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling increaseViewCount.');
            }
            const localVarPath = `/v1/public/menus/{id}/viewCount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeActive: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling makeActive.');
            }
            const localVarPath = `/v1/menus/{id}/active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeInactive: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling makeInactive.');
            }
            const localVarPath = `/v1/menus/{id}/active`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMenu: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeMenu.');
            }
            const localVarPath = `/v1/menus/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreMenu: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling restoreMenu.');
            }
            const localVarPath = `/v1/menus/{id}/restore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MenuDto} menuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenu: async (menuDto: MenuDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuDto' is not null or undefined
            if (menuDto === null || menuDto === undefined) {
                throw new RequiredError('menuDto','Required parameter menuDto was null or undefined when calling updateMenu.');
            }
            const localVarPath = `/v1/menus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof menuDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(menuDto !== undefined ? menuDto : {}) : (menuDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<MenuSequenceDto>} menuSequenceDto 
         * @param {string} [businessId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSequences: async (menuSequenceDto: Array<MenuSequenceDto>, businessId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'menuSequenceDto' is not null or undefined
            if (menuSequenceDto === null || menuSequenceDto === undefined) {
                throw new RequiredError('menuSequenceDto','Required parameter menuSequenceDto was null or undefined when calling updateSequences.');
            }
            const localVarPath = `/v1/menus/sequence`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (businessId !== undefined) {
                localVarQueryParameter['businessId'] = businessId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof menuSequenceDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(menuSequenceDto !== undefined ? menuSequenceDto : {}) : (menuSequenceDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuResourceApi - functional programming interface
 * @export
 */
export const MenuResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MenuDto} menuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMenu(menuDto: MenuDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuDto>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).createMenu(menuDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<MenuDto>} menuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMenus(menuDto: Array<MenuDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuDto>>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).createMenus(menuDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} designId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDesignMenuObjects(designId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimberItemDto>>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).getDesignMenuObjects(designId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenu(menuId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenuDto>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).getMenu(menuId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenus(memberId: number, businessId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuDto>>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).getMenus(memberId, businessId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [businessId] 
         * @param {string} [key] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimberMoMenus(businessId?: number, key?: string, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimberMenuMoResponse>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).getTimberMoMenus(businessId, key, page, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async increaseViewCount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).increaseViewCount(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeActive(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).makeActive(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeInactive(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).makeInactive(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMenu(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).removeMenu(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreMenu(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).restoreMenu(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MenuDto} menuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMenu(menuDto: MenuDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).updateMenu(menuDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<MenuSequenceDto>} menuSequenceDto 
         * @param {string} [businessId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSequences(menuSequenceDto: Array<MenuSequenceDto>, businessId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MenuResourceApiAxiosParamCreator(configuration).updateSequences(menuSequenceDto, businessId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MenuResourceApi - factory interface
 * @export
 */
export const MenuResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {MenuDto} menuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenu(menuDto: MenuDto, options?: any): AxiosPromise<MenuDto> {
            return MenuResourceApiFp(configuration).createMenu(menuDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<MenuDto>} menuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMenus(menuDto: Array<MenuDto>, options?: any): AxiosPromise<Array<MenuDto>> {
            return MenuResourceApiFp(configuration).createMenus(menuDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} designId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesignMenuObjects(designId: string, options?: any): AxiosPromise<Array<TimberItemDto>> {
            return MenuResourceApiFp(configuration).getDesignMenuObjects(designId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} menuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenu(menuId: string, options?: any): AxiosPromise<MenuDto> {
            return MenuResourceApiFp(configuration).getMenu(menuId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {number} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenus(memberId: number, businessId: number, options?: any): AxiosPromise<Array<MenuDto>> {
            return MenuResourceApiFp(configuration).getMenus(memberId, businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [businessId] 
         * @param {string} [key] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimberMoMenus(businessId?: number, key?: string, page?: number, options?: any): AxiosPromise<TimberMenuMoResponse> {
            return MenuResourceApiFp(configuration).getTimberMoMenus(businessId, key, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseViewCount(id: string, options?: any): AxiosPromise<void> {
            return MenuResourceApiFp(configuration).increaseViewCount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeActive(id: string, options?: any): AxiosPromise<boolean> {
            return MenuResourceApiFp(configuration).makeActive(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeInactive(id: string, options?: any): AxiosPromise<boolean> {
            return MenuResourceApiFp(configuration).makeInactive(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMenu(id: string, options?: any): AxiosPromise<void> {
            return MenuResourceApiFp(configuration).removeMenu(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreMenu(id: string, options?: any): AxiosPromise<void> {
            return MenuResourceApiFp(configuration).restoreMenu(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MenuDto} menuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMenu(menuDto: MenuDto, options?: any): AxiosPromise<void> {
            return MenuResourceApiFp(configuration).updateMenu(menuDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<MenuSequenceDto>} menuSequenceDto 
         * @param {string} [businessId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSequences(menuSequenceDto: Array<MenuSequenceDto>, businessId?: string, options?: any): AxiosPromise<void> {
            return MenuResourceApiFp(configuration).updateSequences(menuSequenceDto, businessId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MenuResourceApi - object-oriented interface
 * @export
 * @class MenuResourceApi
 * @extends {BaseAPI}
 */
export class MenuResourceApi extends BaseAPI {
    /**
     * 
     * @param {MenuDto} menuDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public createMenu(menuDto: MenuDto, options?: any) {
        return MenuResourceApiFp(this.configuration).createMenu(menuDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<MenuDto>} menuDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public createMenus(menuDto: Array<MenuDto>, options?: any) {
        return MenuResourceApiFp(this.configuration).createMenus(menuDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} designId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public getDesignMenuObjects(designId: string, options?: any) {
        return MenuResourceApiFp(this.configuration).getDesignMenuObjects(designId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} menuId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public getMenu(menuId: string, options?: any) {
        return MenuResourceApiFp(this.configuration).getMenu(menuId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {number} businessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public getMenus(memberId: number, businessId: number, options?: any) {
        return MenuResourceApiFp(this.configuration).getMenus(memberId, businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [businessId] 
     * @param {string} [key] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public getTimberMoMenus(businessId?: number, key?: string, page?: number, options?: any) {
        return MenuResourceApiFp(this.configuration).getTimberMoMenus(businessId, key, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public increaseViewCount(id: string, options?: any) {
        return MenuResourceApiFp(this.configuration).increaseViewCount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public makeActive(id: string, options?: any) {
        return MenuResourceApiFp(this.configuration).makeActive(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public makeInactive(id: string, options?: any) {
        return MenuResourceApiFp(this.configuration).makeInactive(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public removeMenu(id: string, options?: any) {
        return MenuResourceApiFp(this.configuration).removeMenu(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public restoreMenu(id: string, options?: any) {
        return MenuResourceApiFp(this.configuration).restoreMenu(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MenuDto} menuDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public updateMenu(menuDto: MenuDto, options?: any) {
        return MenuResourceApiFp(this.configuration).updateMenu(menuDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<MenuSequenceDto>} menuSequenceDto 
     * @param {string} [businessId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuResourceApi
     */
    public updateSequences(menuSequenceDto: Array<MenuSequenceDto>, businessId?: string, options?: any) {
        return MenuResourceApiFp(this.configuration).updateSequences(menuSequenceDto, businessId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OrderResourceApi - axios parameter creator
 * @export
 */
export const OrderResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddOrderItemDto} addOrderItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrderItem: async (addOrderItemDto: AddOrderItemDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addOrderItemDto' is not null or undefined
            if (addOrderItemDto === null || addOrderItemDto === undefined) {
                throw new RequiredError('addOrderItemDto','Required parameter addOrderItemDto was null or undefined when calling addOrderItem.');
            }
            const localVarPath = `/v1/public/orders/items`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof addOrderItemDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(addOrderItemDto !== undefined ? addOrderItemDto : {}) : (addOrderItemDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyCouponCode: async (orderId: string, code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling applyCouponCode.');
            }
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling applyCouponCode.');
            }
            const localVarPath = `/v1/public/orders/{orderId}/coupon`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {OrderTotalDto} orderTotalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateTotal: async (id: string, orderTotalDto: OrderTotalDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling calculateTotal.');
            }
            // verify required parameter 'orderTotalDto' is not null or undefined
            if (orderTotalDto === null || orderTotalDto === undefined) {
                throw new RequiredError('orderTotalDto','Required parameter orderTotalDto was null or undefined when calling calculateTotal.');
            }
            const localVarPath = `/v1/orders/{id}/calculateTotal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orderTotalDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orderTotalDto !== undefined ? orderTotalDto : {}) : (orderTotalDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        decreaseQuantity: async (orderId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling decreaseQuantity.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling decreaseQuantity.');
            }
            const localVarPath = `/v1/public/orders/{orderId}/items/{itemId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} friendlyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByFriendlyId: async (friendlyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'friendlyId' is not null or undefined
            if (friendlyId === null || friendlyId === undefined) {
                throw new RequiredError('friendlyId','Required parameter friendlyId was null or undefined when calling findByFriendlyId.');
            }
            const localVarPath = `/v1/public/orders/friendly`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (friendlyId !== undefined) {
                localVarQueryParameter['friendlyId'] = friendlyId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOrder.');
            }
            const localVarPath = `/v1/public/orders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseQuantity: async (orderId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling increaseQuantity.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling increaseQuantity.');
            }
            const localVarPath = `/v1/public/orders/{orderId}/items/{itemId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {OrderDto} orderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrder: async (id: string, orderDto: OrderDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchOrder.');
            }
            // verify required parameter 'orderDto' is not null or undefined
            if (orderDto === null || orderDto === undefined) {
                throw new RequiredError('orderDto','Required parameter orderDto was null or undefined when calling patchOrder.');
            }
            const localVarPath = `/v1/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orderDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orderDto !== undefined ? orderDto : {}) : (orderDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrder: async (orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling removeOrder.');
            }
            const localVarPath = `/v1/public/orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {OrderDto} orderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitOrder: async (id: string, orderDto: OrderDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling submitOrder.');
            }
            // verify required parameter 'orderDto' is not null or undefined
            if (orderDto === null || orderDto === undefined) {
                throw new RequiredError('orderDto','Required parameter orderDto was null or undefined when calling submitOrder.');
            }
            const localVarPath = `/v1/public/orders/{id}/submit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orderDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orderDto !== undefined ? orderDto : {}) : (orderDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {'DELIVERY' | 'PICKUP' | 'NONE'} delivery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDelivery: async (orderId: string, delivery: 'DELIVERY' | 'PICKUP' | 'NONE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling updateDelivery.');
            }
            // verify required parameter 'delivery' is not null or undefined
            if (delivery === null || delivery === undefined) {
                throw new RequiredError('delivery','Required parameter delivery was null or undefined when calling updateDelivery.');
            }
            const localVarPath = `/v1/public/orders/{orderId}/delivery`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (delivery !== undefined) {
                localVarQueryParameter['delivery'] = delivery;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} itemId 
         * @param {number} quantity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemQuantity: async (orderId: string, itemId: string, quantity: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling updateItemQuantity.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateItemQuantity.');
            }
            // verify required parameter 'quantity' is not null or undefined
            if (quantity === null || quantity === undefined) {
                throw new RequiredError('quantity','Required parameter quantity was null or undefined when calling updateItemQuantity.');
            }
            const localVarPath = `/v1/public/orders/{orderId}/items/{itemId}/quantity`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (quantity !== undefined) {
                localVarQueryParameter['quantity'] = quantity;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {number} tipValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemTip: async (orderId: string, tipValue: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling updateItemTip.');
            }
            // verify required parameter 'tipValue' is not null or undefined
            if (tipValue === null || tipValue === undefined) {
                throw new RequiredError('tipValue','Required parameter tipValue was null or undefined when calling updateItemTip.');
            }
            const localVarPath = `/v1/public/orders/{orderId}/tipValue`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tipValue !== undefined) {
                localVarQueryParameter['tipValue'] = tipValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderStatusDto} updateOrderStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatus: async (id: string, updateOrderStatusDto: UpdateOrderStatusDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOrderStatus.');
            }
            // verify required parameter 'updateOrderStatusDto' is not null or undefined
            if (updateOrderStatusDto === null || updateOrderStatusDto === undefined) {
                throw new RequiredError('updateOrderStatusDto','Required parameter updateOrderStatusDto was null or undefined when calling updateOrderStatus.');
            }
            const localVarPath = `/v1/orders/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateOrderStatusDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateOrderStatusDto !== undefined ? updateOrderStatusDto : {}) : (updateOrderStatusDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderResourceApi - functional programming interface
 * @export
 */
export const OrderResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddOrderItemDto} addOrderItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrderItem(addOrderItemDto: AddOrderItemDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderChangedDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).addOrderItem(addOrderItemDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyCouponCode(orderId: string, code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderChangedDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).applyCouponCode(orderId, code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {OrderTotalDto} orderTotalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateTotal(id: string, orderTotalDto: OrderTotalDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).calculateTotal(id, orderTotalDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async decreaseQuantity(orderId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderChangedDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).decreaseQuantity(orderId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} friendlyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByFriendlyId(friendlyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).findByFriendlyId(friendlyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).getOrder(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async increaseQuantity(orderId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderChangedDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).increaseQuantity(orderId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {OrderDto} orderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrder(id: string, orderDto: OrderDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).patchOrder(id, orderDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOrder(orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).removeOrder(orderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {OrderDto} orderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitOrder(id: string, orderDto: OrderDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).submitOrder(id, orderDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {'DELIVERY' | 'PICKUP' | 'NONE'} delivery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDelivery(orderId: string, delivery: 'DELIVERY' | 'PICKUP' | 'NONE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderChangedDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).updateDelivery(orderId, delivery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} itemId 
         * @param {number} quantity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemQuantity(orderId: string, itemId: string, quantity: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderChangedDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).updateItemQuantity(orderId, itemId, quantity, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {number} tipValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemTip(orderId: string, tipValue: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderChangedDto>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).updateItemTip(orderId, tipValue, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderStatusDto} updateOrderStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderStatus(id: string, updateOrderStatusDto: UpdateOrderStatusDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await OrderResourceApiAxiosParamCreator(configuration).updateOrderStatus(id, updateOrderStatusDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrderResourceApi - factory interface
 * @export
 */
export const OrderResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AddOrderItemDto} addOrderItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrderItem(addOrderItemDto: AddOrderItemDto, options?: any): AxiosPromise<OrderChangedDto> {
            return OrderResourceApiFp(configuration).addOrderItem(addOrderItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyCouponCode(orderId: string, code: string, options?: any): AxiosPromise<OrderChangedDto> {
            return OrderResourceApiFp(configuration).applyCouponCode(orderId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {OrderTotalDto} orderTotalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateTotal(id: string, orderTotalDto: OrderTotalDto, options?: any): AxiosPromise<OrderDto> {
            return OrderResourceApiFp(configuration).calculateTotal(id, orderTotalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        decreaseQuantity(orderId: string, itemId: string, options?: any): AxiosPromise<OrderChangedDto> {
            return OrderResourceApiFp(configuration).decreaseQuantity(orderId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} friendlyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByFriendlyId(friendlyId: string, options?: any): AxiosPromise<OrderDto> {
            return OrderResourceApiFp(configuration).findByFriendlyId(friendlyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(id: string, options?: any): AxiosPromise<OrderDto> {
            return OrderResourceApiFp(configuration).getOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseQuantity(orderId: string, itemId: string, options?: any): AxiosPromise<OrderChangedDto> {
            return OrderResourceApiFp(configuration).increaseQuantity(orderId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {OrderDto} orderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrder(id: string, orderDto: OrderDto, options?: any): AxiosPromise<boolean> {
            return OrderResourceApiFp(configuration).patchOrder(id, orderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrder(orderId: string, options?: any): AxiosPromise<boolean> {
            return OrderResourceApiFp(configuration).removeOrder(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {OrderDto} orderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitOrder(id: string, orderDto: OrderDto, options?: any): AxiosPromise<boolean> {
            return OrderResourceApiFp(configuration).submitOrder(id, orderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {'DELIVERY' | 'PICKUP' | 'NONE'} delivery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDelivery(orderId: string, delivery: 'DELIVERY' | 'PICKUP' | 'NONE', options?: any): AxiosPromise<OrderChangedDto> {
            return OrderResourceApiFp(configuration).updateDelivery(orderId, delivery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} itemId 
         * @param {number} quantity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemQuantity(orderId: string, itemId: string, quantity: number, options?: any): AxiosPromise<OrderChangedDto> {
            return OrderResourceApiFp(configuration).updateItemQuantity(orderId, itemId, quantity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {number} tipValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemTip(orderId: string, tipValue: number, options?: any): AxiosPromise<OrderChangedDto> {
            return OrderResourceApiFp(configuration).updateItemTip(orderId, tipValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderStatusDto} updateOrderStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatus(id: string, updateOrderStatusDto: UpdateOrderStatusDto, options?: any): AxiosPromise<boolean> {
            return OrderResourceApiFp(configuration).updateOrderStatus(id, updateOrderStatusDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderResourceApi - object-oriented interface
 * @export
 * @class OrderResourceApi
 * @extends {BaseAPI}
 */
export class OrderResourceApi extends BaseAPI {
    /**
     * 
     * @param {AddOrderItemDto} addOrderItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public addOrderItem(addOrderItemDto: AddOrderItemDto, options?: any) {
        return OrderResourceApiFp(this.configuration).addOrderItem(addOrderItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public applyCouponCode(orderId: string, code: string, options?: any) {
        return OrderResourceApiFp(this.configuration).applyCouponCode(orderId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {OrderTotalDto} orderTotalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public calculateTotal(id: string, orderTotalDto: OrderTotalDto, options?: any) {
        return OrderResourceApiFp(this.configuration).calculateTotal(id, orderTotalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public decreaseQuantity(orderId: string, itemId: string, options?: any) {
        return OrderResourceApiFp(this.configuration).decreaseQuantity(orderId, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} friendlyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public findByFriendlyId(friendlyId: string, options?: any) {
        return OrderResourceApiFp(this.configuration).findByFriendlyId(friendlyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public getOrder(id: string, options?: any) {
        return OrderResourceApiFp(this.configuration).getOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public increaseQuantity(orderId: string, itemId: string, options?: any) {
        return OrderResourceApiFp(this.configuration).increaseQuantity(orderId, itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {OrderDto} orderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public patchOrder(id: string, orderDto: OrderDto, options?: any) {
        return OrderResourceApiFp(this.configuration).patchOrder(id, orderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public removeOrder(orderId: string, options?: any) {
        return OrderResourceApiFp(this.configuration).removeOrder(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {OrderDto} orderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public submitOrder(id: string, orderDto: OrderDto, options?: any) {
        return OrderResourceApiFp(this.configuration).submitOrder(id, orderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {'DELIVERY' | 'PICKUP' | 'NONE'} delivery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public updateDelivery(orderId: string, delivery: 'DELIVERY' | 'PICKUP' | 'NONE', options?: any) {
        return OrderResourceApiFp(this.configuration).updateDelivery(orderId, delivery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {string} itemId 
     * @param {number} quantity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public updateItemQuantity(orderId: string, itemId: string, quantity: number, options?: any) {
        return OrderResourceApiFp(this.configuration).updateItemQuantity(orderId, itemId, quantity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {number} tipValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public updateItemTip(orderId: string, tipValue: number, options?: any) {
        return OrderResourceApiFp(this.configuration).updateItemTip(orderId, tipValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateOrderStatusDto} updateOrderStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderResourceApi
     */
    public updateOrderStatus(id: string, updateOrderStatusDto: UpdateOrderStatusDto, options?: any) {
        return OrderResourceApiFp(this.configuration).updateOrderStatus(id, updateOrderStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PaymentResourceApi - axios parameter creator
 * @export
 */
export const PaymentResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentIntentForOrder: async (orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling createPaymentIntentForOrder.');
            }
            const localVarPath = `/v1/public/payment/create-intent`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectedAccount: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling getConnectedAccount.');
            }
            const localVarPath = `/v1/payment/connected-account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkAccount: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling linkAccount.');
            }
            const localVarPath = `/v1/payment/link-account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerConnectedAccount: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling registerConnectedAccount.');
            }
            const localVarPath = `/v1/payment/connected-account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentResourceApi - functional programming interface
 * @export
 */
export const PaymentResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPaymentIntentForOrder(orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await PaymentResourceApiAxiosParamCreator(configuration).createPaymentIntentForOrder(orderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectedAccount(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectedAccountDto>> {
            const localVarAxiosArgs = await PaymentResourceApiAxiosParamCreator(configuration).getConnectedAccount(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkAccount(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await PaymentResourceApiAxiosParamCreator(configuration).linkAccount(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerConnectedAccount(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await PaymentResourceApiAxiosParamCreator(configuration).registerConnectedAccount(memberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PaymentResourceApi - factory interface
 * @export
 */
export const PaymentResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentIntentForOrder(orderId: string, options?: any): AxiosPromise<string> {
            return PaymentResourceApiFp(configuration).createPaymentIntentForOrder(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectedAccount(memberId: number, options?: any): AxiosPromise<ConnectedAccountDto> {
            return PaymentResourceApiFp(configuration).getConnectedAccount(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkAccount(memberId: number, options?: any): AxiosPromise<string> {
            return PaymentResourceApiFp(configuration).linkAccount(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerConnectedAccount(memberId: number, options?: any): AxiosPromise<string> {
            return PaymentResourceApiFp(configuration).registerConnectedAccount(memberId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentResourceApi - object-oriented interface
 * @export
 * @class PaymentResourceApi
 * @extends {BaseAPI}
 */
export class PaymentResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentResourceApi
     */
    public createPaymentIntentForOrder(orderId: string, options?: any) {
        return PaymentResourceApiFp(this.configuration).createPaymentIntentForOrder(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentResourceApi
     */
    public getConnectedAccount(memberId: number, options?: any) {
        return PaymentResourceApiFp(this.configuration).getConnectedAccount(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentResourceApi
     */
    public linkAccount(memberId: number, options?: any) {
        return PaymentResourceApiFp(this.configuration).linkAccount(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentResourceApi
     */
    public registerConnectedAccount(memberId: number, options?: any) {
        return PaymentResourceApiFp(this.configuration).registerConnectedAccount(memberId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * QrCodeResourceApi - axios parameter creator
 * @export
 */
export const QrCodeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} memberId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMemberQRCodes: async (memberId: number, token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            if (memberId === null || memberId === undefined) {
                throw new RequiredError('memberId','Required parameter memberId was null or undefined when calling countMemberQRCodes.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling countMemberQRCodes.');
            }
            const localVarPath = `/v1/qrcodes/member/count`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {QRCodeDto} qRCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQrCode: async (token: string, qRCodeDto: QRCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createQrCode.');
            }
            // verify required parameter 'qRCodeDto' is not null or undefined
            if (qRCodeDto === null || qRCodeDto === undefined) {
                throw new RequiredError('qRCodeDto','Required parameter qRCodeDto was null or undefined when calling createQrCode.');
            }
            const localVarPath = `/v1/qrcodes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof qRCodeDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(qRCodeDto !== undefined ? qRCodeDto : {}) : (qRCodeDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineMenuHasQRCode: async (businessId: string, token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            if (businessId === null || businessId === undefined) {
                throw new RequiredError('businessId','Required parameter businessId was null or undefined when calling onlineMenuHasQRCode.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling onlineMenuHasQRCode.');
            }
            const localVarPath = `/v1/qrcodes/online-menu/{businessId}/has`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QrCodeResourceApi - functional programming interface
 * @export
 */
export const QrCodeResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} memberId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countMemberQRCodes(memberId: number, token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await QrCodeResourceApiAxiosParamCreator(configuration).countMemberQRCodes(memberId, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {QRCodeDto} qRCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQrCode(token: string, qRCodeDto: QRCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QRCodeDto>> {
            const localVarAxiosArgs = await QrCodeResourceApiAxiosParamCreator(configuration).createQrCode(token, qRCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onlineMenuHasQRCode(businessId: string, token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await QrCodeResourceApiAxiosParamCreator(configuration).onlineMenuHasQRCode(businessId, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * QrCodeResourceApi - factory interface
 * @export
 */
export const QrCodeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} memberId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMemberQRCodes(memberId: number, token: string, options?: any): AxiosPromise<string> {
            return QrCodeResourceApiFp(configuration).countMemberQRCodes(memberId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {QRCodeDto} qRCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQrCode(token: string, qRCodeDto: QRCodeDto, options?: any): AxiosPromise<QRCodeDto> {
            return QrCodeResourceApiFp(configuration).createQrCode(token, qRCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onlineMenuHasQRCode(businessId: string, token: string, options?: any): AxiosPromise<string> {
            return QrCodeResourceApiFp(configuration).onlineMenuHasQRCode(businessId, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QrCodeResourceApi - object-oriented interface
 * @export
 * @class QrCodeResourceApi
 * @extends {BaseAPI}
 */
export class QrCodeResourceApi extends BaseAPI {
    /**
     * 
     * @param {number} memberId 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QrCodeResourceApi
     */
    public countMemberQRCodes(memberId: number, token: string, options?: any) {
        return QrCodeResourceApiFp(this.configuration).countMemberQRCodes(memberId, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {QRCodeDto} qRCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QrCodeResourceApi
     */
    public createQrCode(token: string, qRCodeDto: QRCodeDto, options?: any) {
        return QrCodeResourceApiFp(this.configuration).createQrCode(token, qRCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QrCodeResourceApi
     */
    public onlineMenuHasQRCode(businessId: string, token: string, options?: any) {
        return QrCodeResourceApiFp(this.configuration).onlineMenuHasQRCode(businessId, token, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TaxResourceApi - axios parameter creator
 * @export
 */
export const TaxResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CustomTaxDto} customTaxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTax: async (id: string, customTaxDto: CustomTaxDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createTax.');
            }
            // verify required parameter 'customTaxDto' is not null or undefined
            if (customTaxDto === null || customTaxDto === undefined) {
                throw new RequiredError('customTaxDto','Required parameter customTaxDto was null or undefined when calling createTax.');
            }
            const localVarPath = `/v1/businesses/{id}/taxes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof customTaxDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customTaxDto !== undefined ? customTaxDto : {}) : (customTaxDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessTaxes: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBusinessTaxes.');
            }
            const localVarPath = `/v1/businesses/{id}/taxes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} taxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTax: async (id: string, taxId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeTax.');
            }
            // verify required parameter 'taxId' is not null or undefined
            if (taxId === null || taxId === undefined) {
                throw new RequiredError('taxId','Required parameter taxId was null or undefined when calling removeTax.');
            }
            const localVarPath = `/v1/businesses/{id}/taxes/{taxId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"taxId"}}`, encodeURIComponent(String(taxId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} taxId 
         * @param {CustomTaxDto} customTaxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTax: async (id: string, taxId: string, customTaxDto: CustomTaxDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTax.');
            }
            // verify required parameter 'taxId' is not null or undefined
            if (taxId === null || taxId === undefined) {
                throw new RequiredError('taxId','Required parameter taxId was null or undefined when calling updateTax.');
            }
            // verify required parameter 'customTaxDto' is not null or undefined
            if (customTaxDto === null || customTaxDto === undefined) {
                throw new RequiredError('customTaxDto','Required parameter customTaxDto was null or undefined when calling updateTax.');
            }
            const localVarPath = `/v1/businesses/{id}/taxes/{taxId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"taxId"}}`, encodeURIComponent(String(taxId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof customTaxDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customTaxDto !== undefined ? customTaxDto : {}) : (customTaxDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxResourceApi - functional programming interface
 * @export
 */
export const TaxResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CustomTaxDto} customTaxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTax(id: string, customTaxDto: CustomTaxDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTaxDto>> {
            const localVarAxiosArgs = await TaxResourceApiAxiosParamCreator(configuration).createTax(id, customTaxDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessTaxes(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomTaxDto>>> {
            const localVarAxiosArgs = await TaxResourceApiAxiosParamCreator(configuration).getBusinessTaxes(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} taxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTax(id: string, taxId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await TaxResourceApiAxiosParamCreator(configuration).removeTax(id, taxId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} taxId 
         * @param {CustomTaxDto} customTaxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTax(id: string, taxId: string, customTaxDto: CustomTaxDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTaxDto>> {
            const localVarAxiosArgs = await TaxResourceApiAxiosParamCreator(configuration).updateTax(id, taxId, customTaxDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TaxResourceApi - factory interface
 * @export
 */
export const TaxResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CustomTaxDto} customTaxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTax(id: string, customTaxDto: CustomTaxDto, options?: any): AxiosPromise<CustomTaxDto> {
            return TaxResourceApiFp(configuration).createTax(id, customTaxDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessTaxes(id: string, options?: any): AxiosPromise<Array<CustomTaxDto>> {
            return TaxResourceApiFp(configuration).getBusinessTaxes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} taxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTax(id: string, taxId: string, options?: any): AxiosPromise<boolean> {
            return TaxResourceApiFp(configuration).removeTax(id, taxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} taxId 
         * @param {CustomTaxDto} customTaxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTax(id: string, taxId: string, customTaxDto: CustomTaxDto, options?: any): AxiosPromise<CustomTaxDto> {
            return TaxResourceApiFp(configuration).updateTax(id, taxId, customTaxDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaxResourceApi - object-oriented interface
 * @export
 * @class TaxResourceApi
 * @extends {BaseAPI}
 */
export class TaxResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {CustomTaxDto} customTaxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxResourceApi
     */
    public createTax(id: string, customTaxDto: CustomTaxDto, options?: any) {
        return TaxResourceApiFp(this.configuration).createTax(id, customTaxDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxResourceApi
     */
    public getBusinessTaxes(id: string, options?: any) {
        return TaxResourceApiFp(this.configuration).getBusinessTaxes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} taxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxResourceApi
     */
    public removeTax(id: string, taxId: string, options?: any) {
        return TaxResourceApiFp(this.configuration).removeTax(id, taxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} taxId 
     * @param {CustomTaxDto} customTaxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxResourceApi
     */
    public updateTax(id: string, taxId: string, customTaxDto: CustomTaxDto, options?: any) {
        return TaxResourceApiFp(this.configuration).updateTax(id, taxId, customTaxDto, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * WebsiteResourceApi - axios parameter creator
 * @export
 */
export const WebsiteResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPage: async (id: string, pageDto: PageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createPage.');
            }
            // verify required parameter 'pageDto' is not null or undefined
            if (pageDto === null || pageDto === undefined) {
                throw new RequiredError('pageDto','Required parameter pageDto was null or undefined when calling createPage.');
            }
            const localVarPath = `/v1/website/{id}/pages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof pageDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pageDto !== undefined ? pageDto : {}) : (pageDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} wid 
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePage: async (wid: string, pid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wid' is not null or undefined
            if (wid === null || wid === undefined) {
                throw new RequiredError('wid','Required parameter wid was null or undefined when calling deletePage.');
            }
            // verify required parameter 'pid' is not null or undefined
            if (pid === null || pid === undefined) {
                throw new RequiredError('pid','Required parameter pid was null or undefined when calling deletePage.');
            }
            const localVarPath = `/v1/website/{wid}/page/{pid}`
                .replace(`{${"wid"}}`, encodeURIComponent(String(wid)))
                .replace(`{${"pid"}}`, encodeURIComponent(String(pid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getConfig.');
            }
            const localVarPath = `/v1/website/{id}/config`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} wid 
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPage: async (wid: string, pid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wid' is not null or undefined
            if (wid === null || wid === undefined) {
                throw new RequiredError('wid','Required parameter wid was null or undefined when calling getPage.');
            }
            // verify required parameter 'pid' is not null or undefined
            if (pid === null || pid === undefined) {
                throw new RequiredError('pid','Required parameter pid was null or undefined when calling getPage.');
            }
            const localVarPath = `/v1/website/{wid}/page/{pid}`
                .replace(`{${"wid"}}`, encodeURIComponent(String(wid)))
                .replace(`{${"pid"}}`, encodeURIComponent(String(pid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageByUrl: async (id: string, url: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPageByUrl.');
            }
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError('url','Required parameter url was null or undefined when calling getPageByUrl.');
            }
            const localVarPath = `/v1/public/website/{id}/page/url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPages: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPages.');
            }
            const localVarPath = `/v1/website/{id}/pages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WebsiteDto} websiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, websiteDto: WebsiteDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update.');
            }
            // verify required parameter 'websiteDto' is not null or undefined
            if (websiteDto === null || websiteDto === undefined) {
                throw new RequiredError('websiteDto','Required parameter websiteDto was null or undefined when calling update.');
            }
            const localVarPath = `/v1/website/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof websiteDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(websiteDto !== undefined ? websiteDto : {}) : (websiteDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WebsiteConfig} websiteConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig: async (id: string, websiteConfig: WebsiteConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateConfig.');
            }
            // verify required parameter 'websiteConfig' is not null or undefined
            if (websiteConfig === null || websiteConfig === undefined) {
                throw new RequiredError('websiteConfig','Required parameter websiteConfig was null or undefined when calling updateConfig.');
            }
            const localVarPath = `/v1/website/{id}/config`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof websiteConfig !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(websiteConfig !== undefined ? websiteConfig : {}) : (websiteConfig || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePage: async (id: string, pageDto: PageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePage.');
            }
            // verify required parameter 'pageDto' is not null or undefined
            if (pageDto === null || pageDto === undefined) {
                throw new RequiredError('pageDto','Required parameter pageDto was null or undefined when calling updatePage.');
            }
            const localVarPath = `/v1/website/{id}/pages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof pageDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pageDto !== undefined ? pageDto : {}) : (pageDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebsiteResourceApi - functional programming interface
 * @export
 */
export const WebsiteResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPage(id: string, pageDto: PageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDto>> {
            const localVarAxiosArgs = await WebsiteResourceApiAxiosParamCreator(configuration).createPage(id, pageDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} wid 
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePage(wid: string, pid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WebsiteResourceApiAxiosParamCreator(configuration).deletePage(wid, pid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfig(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebsiteConfig>> {
            const localVarAxiosArgs = await WebsiteResourceApiAxiosParamCreator(configuration).getConfig(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} wid 
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPage(wid: string, pid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDto>> {
            const localVarAxiosArgs = await WebsiteResourceApiAxiosParamCreator(configuration).getPage(wid, pid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageByUrl(id: string, url: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDto>> {
            const localVarAxiosArgs = await WebsiteResourceApiAxiosParamCreator(configuration).getPageByUrl(id, url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPages(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PageDto>>> {
            const localVarAxiosArgs = await WebsiteResourceApiAxiosParamCreator(configuration).getPages(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WebsiteDto} websiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, websiteDto: WebsiteDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await WebsiteResourceApiAxiosParamCreator(configuration).update(id, websiteDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {WebsiteConfig} websiteConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfig(id: string, websiteConfig: WebsiteConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WebsiteResourceApiAxiosParamCreator(configuration).updateConfig(id, websiteConfig, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePage(id: string, pageDto: PageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WebsiteResourceApiAxiosParamCreator(configuration).updatePage(id, pageDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WebsiteResourceApi - factory interface
 * @export
 */
export const WebsiteResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPage(id: string, pageDto: PageDto, options?: any): AxiosPromise<PageDto> {
            return WebsiteResourceApiFp(configuration).createPage(id, pageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} wid 
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePage(wid: string, pid: string, options?: any): AxiosPromise<void> {
            return WebsiteResourceApiFp(configuration).deletePage(wid, pid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(id: string, options?: any): AxiosPromise<WebsiteConfig> {
            return WebsiteResourceApiFp(configuration).getConfig(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} wid 
         * @param {string} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPage(wid: string, pid: string, options?: any): AxiosPromise<PageDto> {
            return WebsiteResourceApiFp(configuration).getPage(wid, pid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageByUrl(id: string, url: string, options?: any): AxiosPromise<PageDto> {
            return WebsiteResourceApiFp(configuration).getPageByUrl(id, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPages(id: string, options?: any): AxiosPromise<Array<PageDto>> {
            return WebsiteResourceApiFp(configuration).getPages(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {WebsiteDto} websiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, websiteDto: WebsiteDto, options?: any): AxiosPromise<boolean> {
            return WebsiteResourceApiFp(configuration).update(id, websiteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {WebsiteConfig} websiteConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfig(id: string, websiteConfig: WebsiteConfig, options?: any): AxiosPromise<void> {
            return WebsiteResourceApiFp(configuration).updateConfig(id, websiteConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PageDto} pageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePage(id: string, pageDto: PageDto, options?: any): AxiosPromise<void> {
            return WebsiteResourceApiFp(configuration).updatePage(id, pageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebsiteResourceApi - object-oriented interface
 * @export
 * @class WebsiteResourceApi
 * @extends {BaseAPI}
 */
export class WebsiteResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {PageDto} pageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteResourceApi
     */
    public createPage(id: string, pageDto: PageDto, options?: any) {
        return WebsiteResourceApiFp(this.configuration).createPage(id, pageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} wid 
     * @param {string} pid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteResourceApi
     */
    public deletePage(wid: string, pid: string, options?: any) {
        return WebsiteResourceApiFp(this.configuration).deletePage(wid, pid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteResourceApi
     */
    public getConfig(id: string, options?: any) {
        return WebsiteResourceApiFp(this.configuration).getConfig(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} wid 
     * @param {string} pid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteResourceApi
     */
    public getPage(wid: string, pid: string, options?: any) {
        return WebsiteResourceApiFp(this.configuration).getPage(wid, pid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteResourceApi
     */
    public getPageByUrl(id: string, url: string, options?: any) {
        return WebsiteResourceApiFp(this.configuration).getPageByUrl(id, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteResourceApi
     */
    public getPages(id: string, options?: any) {
        return WebsiteResourceApiFp(this.configuration).getPages(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {WebsiteDto} websiteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteResourceApi
     */
    public update(id: string, websiteDto: WebsiteDto, options?: any) {
        return WebsiteResourceApiFp(this.configuration).update(id, websiteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {WebsiteConfig} websiteConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteResourceApi
     */
    public updateConfig(id: string, websiteConfig: WebsiteConfig, options?: any) {
        return WebsiteResourceApiFp(this.configuration).updateConfig(id, websiteConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PageDto} pageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebsiteResourceApi
     */
    public updatePage(id: string, pageDto: PageDto, options?: any) {
        return WebsiteResourceApiFp(this.configuration).updatePage(id, pageDto, options).then((request) => request(this.axios, this.basePath));
    }

}


