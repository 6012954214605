import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {BusinessCfg, BusinessDto, LocationDto, LocationDtoTypeEnum} from '../../../gen/client';
import {Button, Card, message, Switch} from "antd";
import './SettingsGeneral.scss';
import SettingsCardHeader from './SettingsCardHeader';
import EditLocationModal from "./EditLocationModal";
import WebsiteIcon from "../../misc/icons/WebsiteIcon";
import SettingsContactLink from './SettingsContactLink';
import ContactIcon from "../../misc/icons/ContactIcon";
import EmailIcon from "../../misc/icons/EmailIcon";
import TwitterIcon from "../../misc/icons/TwitterIcon";
import FacebookIcon from "../../misc/icons/FacebookIcon";
import InstagramIcon from "../../misc/icons/InstagramIcon";
import Loader from "../../misc/Loader";
import {handleServerError} from "../../../util/ErrorHandler";
import {BusinessApi} from "../../../api/BusinessApi";
import memberApi from '../../../api/MemberApi';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {currentLocation, isOwnerState, memberState, savingState} from "../../../store/atoms";
import EditLocationWarningModal from "./EditLocationWarningModal";
import { PLACEHOLDER_WEBSITE, PLACEHOLDER_EMAIL, PLACEHOLDER_FACEBOOK, PLACEHOLDER_TWITTER, PLACEHOLDER_INSTAGRAM } from '../../../util/constants';
import CopyToLocationModal from './CopyToLocationModal';

interface EditRestaurantGeneralProps {
  business: BusinessDto;
  onConfigChange: (patch: Partial<BusinessCfg>) => void;
}

export default function SettingsGeneral({business, onConfigChange}: EditRestaurantGeneralProps) {
  const [location, setLocation] = useRecoilState(currentLocation);
  const member = useRecoilValue(memberState);
  const isOwner = useRecoilValue(isOwnerState);
  const setSaving = useSetRecoilState(savingState);

  const [locations, setLocations] = useState([] as LocationDto[]);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editLocationWarningModal, setEditLocationWarningModal] = useState(false);
  const [copyToLocationModal, setCopyToLocationModal] = useState(false);
  const [copyMenusLoading, setCopyMenusLoading] = useState(false);

  function onEdit() {
    setEditLocationWarningModal(true);
  }

  function onEditLocationWarningContinue() {
    setEditLocationWarningModal(false);
    setShowEditModal(true);
  }

  function onLocationSave() {
    fetchLocation();
    setShowEditModal(false);
  }

  const fetchLocation = useCallback(() => {
    if (!business.id) return;

    setLoading(true);
    BusinessApi.getLocation(parseFloat(business.id)).then(resp => {
      setLocation(resp.data);

      setLoading(false);
    }).catch(e => {
      handleServerError(e);
      setLoading(false);
    });
  }, [business.id, setLocation]);

  useEffect(fetchLocation, [fetchLocation]);

  const fetchLocations = useCallback(() => {
    if (!member.id) return;
    memberApi.getLocations(member.id).then(resp => {
      setLocations(resp.data);
    }).catch(e => {
      handleServerError(e);
    });
  }, [member.id, setLocations]);

  useEffect(fetchLocations, [fetchLocations]);

  const patchLocation = useCallback((newLocation: LocationDto) => {
    setSaving(true);
    BusinessApi.updateLocation(location.internalId, {...location, ...newLocation}).then(resp => {
      setSaving(false);
      setLocation(resp.data);
    }).catch(e => {
      setSaving(false);
      handleServerError(e);
    });
  }, [location, setLocation, setSaving]);

  function onContactLinkChange(location: LocationDto) {
    patchLocation(location);
  }

  function addNewLocation() {
    window.MHM.ProModal.addLocation({memberId: `${member.id}`, 
      onSuccess: () => {
        fetchLocations();
      }
    });
  }

  function onCopyClick() {
    setCopyToLocationModal(true);
  }

  function copyMenusToLocation(locationId: string) {
    setCopyMenusLoading(true);
    memberApi.copyMenusToLocation(member.id, parseInt(business.id), parseInt(locationId)).then(resp => {
      console.log(resp.data);
      setCopyToLocationModal(false);
      setCopyMenusLoading(false);
      message.success('Online Menu successfully copied', 3);
    }).catch(err => {
      console.log(err);
      setCopyMenusLoading(false);
    })
  }

  return <>
    {loading && <Loader/>}
    {!loading &&
    <div className={'settings-general'}>
        <Card className={'business-info'}>
            <SettingsCardHeader name={'Business Info'} onEdit={onEdit} hideTrigger hideEdit triggerChecked={business.config.showBusinessName} onSwitch={showBusinessName => onConfigChange({showBusinessName})}/>

            <section className={'business-info-section'}>
                <div className={'content'}>
                  {location.name}
                </div>
                <div className={'switch'}>
                    <Switch size='small' defaultChecked={business.config.showBusinessName} className={'trigger'} onChange={showBusinessName => onConfigChange({showBusinessName})}/>
                </div>
            </section>

            <section>
                <div className={'content'}>
                    <span>{location.address}</span>
                    {location.address2 && <span>{location.address2}</span>}
                    <span>{location.city} {location.state} {location.zip}</span>
                    <span>{location.country}</span>
                </div>
                <div className={'switch'}>
                    <Switch size='small' defaultChecked={business.config.showAddress} className={'trigger'} onChange={showAddress => onConfigChange({showAddress})}/>
                </div>
            </section>

            <footer>
              {isOwner && <Button className='btn-sec-1' onClick={onEdit}>Edit Location</Button>}
              <Button className='btn-sec-2' onClick={onCopyClick}>Copy to Location</Button>
            </footer>
        </Card>

        <Card className={'contact-links'}>
            <SettingsCardHeader name={'Contact Links'} onEdit={onEdit} hideTrigger hideEdit/>

            <section>
                <SettingsContactLink icon={<ContactIcon/>} isPhone country={location.country} text={location.phone} enabled={business.config.showPhone && !!location.phone} onSwitch={showPhone => onConfigChange({showPhone})} allowEdit onChange={v => onContactLinkChange({phone: v})}/>
                <SettingsContactLink icon={<WebsiteIcon/>} placeholder={PLACEHOLDER_WEBSITE} text={location.website} enabled={business.config.showWebsite && !!location.website} onSwitch={showWebsite => onConfigChange({showWebsite})} allowEdit onChange={v => onContactLinkChange({website: v})}/>
                <SettingsContactLink icon={<EmailIcon/>} placeholder={PLACEHOLDER_EMAIL} text={location.email} enabled={business.config.showEmail && !!location.email} onSwitch={showEmail => onConfigChange({showEmail})} allowEdit onChange={v => onContactLinkChange({email: v})}/>
                <SettingsContactLink icon={<img src="https://timber.mhmcdn.com/site/marketing/icons/X.svg"/>} placeholder={PLACEHOLDER_TWITTER} text={location.twitter} enabled={business.config.showTwitter && !!location.twitter} onSwitch={showTwitter => onConfigChange({showTwitter})} allowEdit onChange={v => onContactLinkChange({twitter: v})}/>
                <SettingsContactLink icon={<FacebookIcon/>} placeholder={PLACEHOLDER_FACEBOOK} text={location.facebook} enabled={business.config.showFacebook && !!location.facebook} onSwitch={showFacebook => onConfigChange({showFacebook})} allowEdit onChange={v => onContactLinkChange({facebook: v})}/>
                <SettingsContactLink icon={<InstagramIcon/>} placeholder={PLACEHOLDER_INSTAGRAM} text={location.instagram} enabled={business.config.showInstagram && !!location.instagram} onSwitch={showInstagram => onConfigChange({showInstagram})} allowEdit onChange={v => onContactLinkChange({instagram: v})}/>
            </section>
        </Card>

        <CopyToLocationModal visible={copyToLocationModal} currentLocation={location} locations={locations} loading={copyMenusLoading} newLocation={addNewLocation} onCancel={() => setCopyToLocationModal(false)} onCopy={copyMenusToLocation}/>
        <EditLocationModal locationId={parseFloat(business.id)} memberId={member.id} visible={showEditModal} onClose={() => setShowEditModal(false)} onSave={onLocationSave}/>
        <EditLocationWarningModal visible={editLocationWarningModal} onCancel={() => setEditLocationWarningModal(false)} onContinue={onEditLocationWarningContinue}/>
    </div>
    }
  </>
}

