import "./EditLocationWarningModal.scss";
import {Modal} from "antd";
import React from "react";
import {getAccountNewLocationPage} from "../../../util/Utils";

export interface EditLocationWarningModalProps {
  visible: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export default function EditLocationWarningModal({visible, onCancel, onContinue}: EditLocationWarningModalProps) {
  const locationAddonPrice = 10;

  return (
    <Modal className={"edit-location-warning-modal"} visible={visible} onCancel={onCancel} okText={'Continue'} onOk={onContinue} width={'600px'}>
      <section>
        <h3>Edit Location</h3>

        <p>
          Changes to Business Name or Address will reflect on all Link Pages and Online Menus for this location.
        </p>
        
        <p className="add-loc-link">
          <a href={getAccountNewLocationPage()} target={'_blank'} rel={'noreferrer'} onClick={onCancel}>
            Add a Location for ${locationAddonPrice}/month each
          </a>
        </p>
      </section>
    </Modal>
  );
}
